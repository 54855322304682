@font-face {
  font-family: 'Montserrat-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat-Regular'), 
        url(../fonts/Montserrat/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-Bold';
  font-style: normal;
  font-weight: 900;
  src: local('Montserrat-Bold'), 
        url(../fonts/Montserrat/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat-SemiBold'), 
        url(../fonts/Montserrat/Montserrat-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-ExtraBold';
  font-style: normal;
  font-weight: 900;
  src: local('Montserrat-ExtraBold'), 
        url(../fonts/Montserrat/Montserrat-ExtraBold.ttf) format('truetype');
}
  
@font-face {
  font-family: 'ProximaNova-Light';
  font-style: normal;
  font-weight: 600;
  src: local('ProximaNova-Light'), 
        url(../fonts/ProximaNova/ProximaNova-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'ProximaNova-Regular';
  font-style: normal;
  font-weight: 600;
  src: local('ProximaNova-Regular'), 
        url(../fonts/ProximaNova/ProximaNova-Regular.ttf) format('truetype');
}
  
@font-face {
  font-family: 'ProximaNova-Medium';
  font-style: normal;
  font-weight: 600;
  src: local('ProximaNova-Medium'), 
        url(../fonts/ProximaNova/ProximaNova-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'ProximaNova-Bold';
  font-style: normal;
  font-weight: 900;
  src: local('ProximaNova-Bold'), 
        url(../fonts/ProximaNova/ProximaNova-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway-Bold';
  font-style: normal;
  font-weight: 900;
  src: local('Raleway-Bold'), 
        url(../fonts/Raleway/Raleway-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Regular';
  font-style: normal;
  font-weight: 900;
  src: local('Poppins-Regular'), 
        url(../fonts/Poppins/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Medium';
  font-style: normal;
  font-weight: 900;
  src: local('Poppins-Medium'), 
        url(../fonts/Poppins/Poppins-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  font-style: normal;
  font-weight: 900;
  src: local('Poppins-SemiBold'), 
        url(../fonts/Poppins/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Bold';
  font-style: normal;
  font-weight: 900;
  src: local('Poppins-Bold'), 
        url(../fonts/Poppins/Poppins-Bold.ttf) format('truetype');
}

#simple-menu{
  z-index: 40000000000 !important;
}

.DishesHeader{
  background-color: #FFF;
}

.HeaderShadowBottom{
  -webkit-box-shadow: #0000001A 0px 3px 12px;
  -moz-box-shadow: #0000001A 0px 3px 12px;
  box-shadow: #0000001A 0px 3px 12px;
}

#dishes-login-button{
  font-family: 'Poppins-Medium';
  background: #FFF 0% 0% no-repeat padding-box;
  box-shadow: none;
  padding: 6px 25px;
  margin-right: 10px;
  color: #404040; 
  font-size: 15px;
  text-transform: capitalize;
}

#dishes-login-button-mobile{
  font-family: 'Poppins-Medium';
  background-color: rgba(0,0,0,0);
  box-shadow: none;
  padding: 6px 10px;
  margin-right: 5px;
  color: #404040; 
  font-size: 14px;
  text-transform: capitalize;
}

#dishes-login-button:hover, #dishes-login-button-mobile:hover{
  color: #EA5B2F;
}
  
#dishes-signup-button {
  color: #FFFFFF;
  font-family: 'Poppins-Medium';
  background: #EA5B2F 0% 0% no-repeat padding-box;
  border-radius: 30px;
  padding: 8px 30px;
  margin-right: 10px;
  font-size: 15px;
  text-transform: capitalize;
  box-shadow: none !important;
}

#dishes-signup-button-mobile {
  color: #FFFFFF;
  font-family: 'Poppins-Medium';
  background: #EA5B2F 0% 0% no-repeat padding-box;
  border-radius: 30px;
  padding: 6px 20px;
  margin-right: 10px;
  font-size: 14px;
  text-transform: capitalize;
  border: 1px solid #EA5B2F;
  box-shadow: none !important;
  margin-top: 4px;
}

#dishes-signup-button:hover, #dishes-signup-button-mobile:hover{
  background: white 0% 0% no-repeat padding-box;
  border: 1px solid #EA5B2F;
  color: #EA5B2F;
}

.OffersAuthBackground{
  padding: 0px 20px;
  padding-top: 20px;
  padding-left: 60px;
  display: inline-block;
}

.HomeAddressBar{
  text-overflow: ellipsis;
  font-family: 'Poppins-Medium';
  color: rgb(64, 64, 64);
  transform: translateY(2px);
}

.OffersCarouselContainer{
  padding-top: 40px;
  padding-bottom: 40px;
  margin-top: 80px;
  /* height: 220px; */
  background-color: #2B2624;
  position: relative;
}

.OffersCarouselContainerM{
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 80px;
  background-color: #2B2624;
  position: relative;
}

.OffersCarousel{
  margin-right: 5vw;
  margin-left: 5vw;
}

.OffersCarouselPrevSlide{
  margin-right: 2vw;
  background-color: #414141;
  padding: 0.5vw 0.9vw 0.8vw 0.9vw;
  color: white;
  border-radius: 50px;
  font-size: 20px;
}

.OffersCarouselNextSlide{
  margin-left: 2vw;
  background-color: #414141;
  padding: 0.5vw 0.9vw 0.8vw 0.9vw;
  color: white;
  border-radius: 50px;
  font-size: 20px;
}

.OffersCard{
  height: 150px; 
  border-radius: 20px; 
  overflow: hidden;
  position: relative;
  width: 100%;
  background-position: 50% 80%; 
  cursor: pointer;
  display: block;
}

.OffersImage{
  width: 100%;
  height: 100%;
  opacity: 0.7;
  display: inline-block;
}

.OffersBlur{
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 40;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.2)
  );
}

.OffersText{
  position: absolute;
  z-index: 999;
  margin: 0 auto;
  bottom: 10%;
  left: 6%; 
  color: #FFF;
  font-family: 'Poppins-Medium';
}

.OffersCount{
  font-family: 'Poppins-Bold';
  font-size: 0.8vw;
}

.OffersDiscount{
  font-family: 'Poppins-Bold';
  font-size: 1.0vw;
  line-height: 17px;
  padding: 0px 0px;
  width: 140px;
}

.OffersDescription{
  font-family: 'Poppins-Medium';
  font-size: 0.9vw;
  width: 100%;
  font-weight: 600;
}

.GlobalSearchBarContainer{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 10000;
    opacity:1;
    -webkit-transition: opacity 100ms ease-in;
    -moz-transition: opacity 100ms ease-in;
    transition: opacity 100ms ease-in;
    pointer-events: auto;
    cursor: pointer;
    overflow: hidden;
    display: flex;
}

.GlobalSearchBarSuper{
  width: 50vw;
  margin: auto;
  min-width: 300px;
  border-radius: 40px;
  overflow: hidden;
  background-color: #FFFFFF !important;
  position: relative;
  /* margin-left: 0px; */
}

.GlobalSearchBar {
  border: 0px solid transparent;
  border-radius: 2px;
  padding: 1rem;
  width: 90%;
  padding-right: 40px;
  background-color: transparent;
  font-family: 'Montserrat-Regular';
}

.GlobalSearchBar::placeholder{
  color: black !important;
  font-family: 'Montserrat-Regular';
  font-size: 12px;
}

.GlobalSearchBar:focus {
  outline: none;
}

.GlobalSearchIcon{
  position: relative;
  top: 15px;
  left: 10px;
  font-size: 18px !important;
  color: gray;
}

.GlobalSearchBarOptions {
  /* position: absolute; */
  /* z-index: 2000; */
  top: 60px;
  width: 50vw;
  padding-top: 10px;
  padding-bottom: 15px;
  list-style: none;
  /* transition: width 0.3s; */
  border-radius: 0px 0px 12px 12px;
  background-color: white;
  box-shadow: 20px 20px 12px #ECECEC;
  max-height: 180px;
  /* overflow: hidden; */
  min-width: 300px;
}

.GlobalSearchBarOptionsContainer{
  overflow: scroll;
  max-height: 180px;
  margin-left: 5px;
}

.GlobalSearchBarOptionsContainer::-webkit-scrollbar{
  width: 0px;
}

.ddMobileBottomBarIcon{
  font-size: 25px;
  color: #5E4B45;
  margin-top: 7px;
}

.SearchBarOptions::-webkit-scrollbar{
  width: 0px;
}

.ddnoScroll::-webkit-scrollbar{
  height: 0px;
}

.GlobalFilteredOption{
  padding: 6px 0px;
  padding-left: 15px;
  font-size: 1.2rem;
  width: 50vw;
  min-width: 300px;
  font-family: 'ProximaNova-Regular';
  font-size: 16px;
  overflow: hidden;
  display: inline-block;
}

.GlobalFilteredOptionActive{
  background-color: #ECECEC;
}

.GlobalFilteredOptionNotFound{
  background: white;
  padding: 8px 3%;
  padding-left: 50px;
  font-size: 1.2rem;
  width: 100%;
  font-family: 'ProximaNova-Regular';
  font-size: 16px;
}

.GlobalFilteredOption:hover {
  background-color: #ECECEC;
  cursor: pointer;
  font-size: 18px;
}

.GlobalClearSearch{
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 20px;
}

.DishesActions{
  margin-top: 0px;
  padding-right: 5vw;
  padding-left: 5vw;
  padding-top: 10px;
}

.DishesActionsHeader{
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 4000000;
  margin-top: 0px;
  padding: 25px 0px;
  width: 100vw;
  background-color: white;
  -webkit-box-shadow: 0 2px 2px -1px #D3D3D3;
  -moz-box-shadow: 0 2px 2px -1px #D3D3D3;
  box-shadow: 0 2px 2px -1px #D3D3D3;
  animation: glow 0.5s;
}

.DishesActionsHeaderDisappear{
  opacity: 0;
  animation: glow-reverse 0.5s;
}

@keyframes glow{
  0%{opacity: 0}
  100%{opacity: 1}
}

@keyframes glow-reverse{
  0%{opacity: 1}
  100%{opacity: 0}
}

.SearchBarContainer{
  padding-left: 1vw;
  /* border-radius: 20px 20px 0px 0px; */
  font-family: 'Poppins-Regular' !important;
  margin-left: 0px;
}

.SearchIcon{
  position: relative;
  top: 5px;
  font-size: 18px !important;
  color: gray;
}

.DishFilters{
  animation: fadeInUp 1.2s ease-in-out 0s forwards;
}

.Filters{
  margin-top: 30px;
}

.First{
  position: relative;
}

.SearchBarSuper{
  position: relative;
  margin-left: 60px;
  margin-right: 20px;
  border-radius: 20px;
  border-radius: 20px 20px 0px 0px;
}

.SearchBar::placeholder{
  color: black !important;
  font-family: 'Poppins-Regular';
}

.SearchBar {
  border: 0px solid transparent;
  border-radius: 2px;
  padding: 1rem;
  width: 90%;
  padding-right: 40px;
  background-color: transparent;
  font-family: 'Montserrat-Regular';
}
.SearchBar:focus {
  outline: none;
}

.SearchBarOptions {
  position: absolute;
  z-index: 2000;
  top: 45px;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  list-style: none;
  /* transition: width 0.3s; */
  border-radius: 0px 0px 20px 20px;
  background-color: white;
  box-shadow: 3px 3px 12px #1B1B1B59;
  overflow: hidden;
}

.CancelSearch{
  cursor: pointer;
  color: gray;
  position: absolute;
  top: 10px;
  right: 20px;
}

.FilteredOption  {
  padding: 8px 3%;
  padding-left: 50px;
  font-size: 1.2rem;
  width: 100%;
  font-family: 'ProximaNova-Regular';
  font-size: 16px;
}

.FilteredOptionActive{
  background-color: #ECECEC;
}

.FilteredOptionNotFound{
  background: white;
  padding: 8px 3%;
  padding-left: 50px;
  font-size: 1.2rem;
  width: 100%;
  font-family: 'ProximaNova-Regular';
  font-size: 16px;
}

.FilteredOption:hover {
  background-color: #ECECEC;
  cursor: pointer;
  font-size: 18px;
}

.SearchText{
  font-family: 'Poppins-Regular';
  margin-left: 0.5vw;
  font-size: 15px;
}

.FiltersContainer{
  margin-right: 3vw;
  margin-left: 3vw;
}

.filter-button{
  display: inline-block;
  font-family: 'Poppins-Regular';
  background: white 0% 0% no-repeat padding-box;
  padding-bottom: 10px;
  box-shadow: none;
  padding: 6px 1.5vw;
  color: #404040; 
  font-size: 16px;
  text-transform: capitalize;
  /* overflow: visible; */
  position: relative;
  cursor: pointer;
}

.filter-button-border{
  content: '';
  height: 2px;
  background: black;
  position: absolute;
  bottom: -4px;
  left: 30%;
  transition: 0.5s ease;
}

.FilterButton{
  padding-right: 3px;
}

.PriceArrow{
  transform: translateY(6px);
}

.FilterRow{
  display: flex;
  font-family: 'Poppins-Regular';
}

.FilterItemsSuper{
  overflow-x: auto; 
  flex: 1;
  overflow-y: hidden;
  white-space: nowrap;
  /* margin-right: 3.5vw; */
  margin-left: 2vw;
  margin-top: 0px;
  padding-bottom: 2px;
}

.FilterItemsSuperM{
  overflow-x: auto; 
  flex: 1;
  overflow-y: hidden;
  white-space: nowrap;
  /* margin-right: 3.5vw; */
  /* margin-left: 2vw; */
  margin-top: 0px;
  padding-bottom: 2px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.FilterItemsSuper::-webkit-scrollbar {
  height: 0px;
}

.FilterItemsSuper::-webkit-scrollbar-track {
  background: white; 
}

.FilterItemsSuper::-webkit-scrollbar-thumb {
  background: #EFEFEF; 
  border-radius: 10px;
}

.FilterItemsSuper::-webkit-scrollbar-thumb:hover {
  background: #888; 
}

/* Hide scrollbar for IE, Edge and Firefox */
/* .FilterItemsSuper {
  -ms-overflow-style: none; 
  scrollbar-width: none;  
} */

.FilterItemsContainer{
  overflow: hidden;
  display: inline-block;
  margin-right: 18px; 
  margin-left: 18px;
  cursor: pointer;
  transition: all 100ms ease-in-out;
}
/* .FilterItemsContainer:hover{ */
  /* text-shadow: #00000029 0px 3px 6px; */
  /* -webkit-filter: drop-shadow(#00000029 5px 5px 3px);
  filter:         drop-shadow(#00000029 5px 5px 3px); 
  transition: all 100ms ease-in-out; */
/* } */

.FilterRestaurantItemsContainer{
  margin-right: 6px; 
  margin-left: 6px;
}


.FilterItemsImage{
  margin: auto;
  width: 50px;
  display: block;
  /* transition: 0.1s ease; */
}

.FilterItemsImageM{
  margin: auto;
  width: 40px;
  height: 40px;
  display: block;
  /* transition: 0.1s ease; */
}

.FilterItemsText{
  margin-top: 1vw;
  font-family: 'Poppins-Regular';
  color: black;
  font-size: 14px;
  text-align: center;
  word-wrap: break-word
}

.FilterRestaurantItemsText{
  margin-top: 0vw !important;
  font-size: 15px !important;
}

/* .FilterRestaurantItemsText:hover{
  color: white !important;
  background-color: #EA5B2F !important;
} */

.FilterClear{
  margin-right: 2vw;
  padding: 6px 1.8vw;
  border: 1px solid #707070;
  border-radius: 40px;
  font-family: 'Montserrat-Regular';
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
}

.DishCardHeaderContainer{
  padding-left: 3vw;
  position: relative;
}

.DishCardHeaderText{
  font-family: 'Quicksand-Bold';
  letter-spacing: -1px;
  font-size: 24px;
}

.DishCardHeaderSupportText{
  margin-top: 2px;
  font-family: 'Poppins-Medium';
  font-size: 15px;
  font-style: italic;
  margin-bottom: 10px;
  cursor: pointer;
}

.VegToggleContainer{
  position: relative;
}

.VegToggle{
  position: absolute;
  top: 3px;
}

.VegToggleText{
  padding-left: 0.5vw;
  color: #3D3D3D;
  font-family: 'Poppins-Regular';
  font-size: 12px;
  cursor: pointer;
}

.ddRestoOfferings{
  display: flex;
  align-content: center;
  background: #FFFDE5;
  color: #5E4B45;
  font-size: 13px;
  font-family: "Poppins-Medium";
  padding: 10px 20px;
}

.Ribbon {
  max-width: calc(80%);
  text-align: center;
  margin-left: -15px;
  margin-right: -10px;
  background: #EA2886;
  position: absolute;
  top: -3px;
  left: 5px;
  /* transform: translateX(-20px); */
  z-index: 45;
  color: white;
  font-family: 'Montserrat-Regular';
  padding: 3px 7px;
  font-size: 11px;
  font-weight: bold !important;
  text-align: left;
}

.RibbonText{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100%);
}

.Ribbon:before, .Ribbon:after {
  content: "";
  position: absolute;
}
.Ribbon:before {
  height: 0;
  width: 0;
  bottom: -10px;
  left: 0px;
  border-top: 10px solid #C71D70;
  border-left: 10px solid transparent;
}

.RibbonFD {
  max-width: calc(80%);
  text-align: center;
  margin-left: -15px;
  margin-right: -10px;
  background: #3356AA;
  position: absolute;
  top: -3px;
  left: 5px;
  /* transform: translateX(-20px); */
  z-index: 45;
  color: white;
  font-family: 'Montserrat-Regular';
  padding: 3px 7px;
  font-size: 11px;
  font-weight: bold !important;
  text-align: left;
}

.RibbonFDText{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100%);
}

.RibbonFD:before, .RibbonFD:after {
  content: "";
  position: absolute;
}
.RibbonFD:before {
  height: 0;
  width: 0;
  bottom: -10px;
  left: 0px;
  border-top: 10px solid #1C3D90;
  border-left: 10px solid transparent;
}

.RibbonM {
  background: #EA2886;
  position: absolute;
  top: 5px;
  right: -8px;
  z-index: 45;
  color: white;
  font-family: 'Poppins-Medium';
  padding: 2px 10px 0px 10px;
  font-size: 11px;
  font-weight: bold !important;
  text-align: left;
  border-radius: 2px;
}

.RibbonTextM{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100%);
}

.RibbonM:before, .RibbonM:after {
  content: "";
  position: absolute;
}
.RibbonM:before {
  height: 0;
  width: 0;
  bottom: -9px;
  right: 0px;
  transform: rotate(270deg);
  border-top: 10px solid #EA2886;
  border-left: 10px solid transparent;
}

.RibbonFDM {
  background: #3356AA;
  position: absolute;
  top: 5px;
  right: -8px;
  z-index: 45;
  color: white;
  font-family: 'Poppins-Medium';
  padding: 2px 10px 1px 10px;
  font-size: 11px;
  font-weight: bold !important;
  text-align: left;
  border-radius: 2px;
}

.RibbonTextFDM{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100%);
}

.RibbonFDM:before, .RibbonFDM:after {
  content: "";
  position: absolute;
}
.RibbonFDM:before {
  height: 0;
  width: 0;
  bottom: -9px;
  right: 0px;
  transform: rotate(270deg);
  border-top: 10px solid #1C3D90;
  border-left: 10px solid transparent;
}

.RibbonMBP {
  background: #EA2886;
  position: absolute;
  top: -5px;
  left: -9px;
  z-index: 45;
  color: white;
  font-family: 'Poppins-Medium';
  padding: 2px 10px 0px 10px;
  font-size: 11px;
  font-weight: bold !important;
  text-align: left;
  border-radius: 2px;
}
.RibbonMBP:before, .RibbonMBP:after {
  content: "";
  position: absolute;
}
.RibbonMBP:before {
  height: 0;
  width: 0;
  bottom: -10px;
  left: 0px;
  border-top: 10px solid #C71D70;
  border-left: 10px solid transparent;
}

.DishCardWrapper{
  margin: 20px auto;
  position: relative;
  padding:5px;
  border-radius: 20px;
  transition: all 100ms ease-in-out;
}
.DishCardWrapper:hover{
  box-shadow: 0px 3px 30px #0000001A;
  transition: all 100ms ease-in-out;
}

.ddPopularDishRibbon{
  width: 60px;
  position: absolute;
  top: -27px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: 20;
}

.ddPopularDishRibbon:hover{
  transform: rotate(90deg);
  transition: all 500ms ease-in-out;
}

.HomeDishCardWrapper{
  width: 280px;
}

.ddHomeDishCardWrapperM{
  width: 100%;
}

.DishDescriptionOverlay{
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 210px;
  background-color: white;
  border-radius: 10px;
  z-index: 200;
  padding: 20px 15px 0px 20px;
  -webkit-transition: visibility 0.4s cubic-bezier(0.39, 0.58, 0.57, 1), opacity 300ms !important;
       -moz-transition: visibility 0.4s cubic-bezier(0.39, 0.58, 0.57, 1), opacity 300ms !important;
        -ms-transition: visibility 0.4s cubic-bezier(0.39, 0.58, 0.57, 1), opacity 300ms !important;
         -o-transition: visibility 0.4s cubic-bezier(0.39, 0.58, 0.57, 1), opacity 300ms !important;
            transition: visibility 0.4s cubic-bezier(0.39, 0.58, 0.57, 1), opacity 300ms !important;
}

.DishDescriptionOverlayM{
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 180px;
  background-color: white;
  border-radius: 10px;
  z-index: 200;
  padding: 20px 15px 0px 20px;
  -webkit-transition: visibility 0.4s cubic-bezier(0.39, 0.58, 0.57, 1), opacity 300ms !important;
       -moz-transition: visibility 0.4s cubic-bezier(0.39, 0.58, 0.57, 1), opacity 300ms !important;
        -ms-transition: visibility 0.4s cubic-bezier(0.39, 0.58, 0.57, 1), opacity 300ms !important;
         -o-transition: visibility 0.4s cubic-bezier(0.39, 0.58, 0.57, 1), opacity 300ms !important;
            transition: visibility 0.4s cubic-bezier(0.39, 0.58, 0.57, 1), opacity 300ms !important;
}

.fade-in {
	animation: fadeIn 2s;
  	opacity: 1;
}

@keyframes fadeIn {
  from {
  	opacity: 0;
  }
  to {
 	opacity: 1;
  }
}

.DishDescriptionOverlayHeader{
  color: #EA5B2F;
  font-size: 14px;
  font-family: 'Poppins-Medium';
  /* margin-bottom: 10px; */
}

.DishDescriptionOverlayText{
  font-family: 'Poppins-Regular';
  color: #2B2624;
  font-size: 12px;
  line-height: 20px;
  height: 140px;
  overflow: scroll;
  text-overflow: ellipsis;
}

.DishDescriptionOverlayText::-webkit-scrollbar{
  display: none;
}

.DishDescriptionOverlayTextM{
  font-family: 'Poppins-Regular';
  color: #2B2624;
  font-size: 14px;
  line-height: 20px;
  height: 120px;
  overflow: scroll;
  text-overflow: ellipsis;
}

.DishDescriptionOverlayTextM::-webkit-scrollbar{
  display: none;
}

.DishCardTopContainer {
  position: relative;
  height: 115px;
  /* overflow: hidden; */
  padding: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.DishCardTopContainerM {
  position: relative;
  border-top: 1px solid #F7F3D2;
  border-right: 1px solid #F7F3D2;
  border-left: 1px solid #F7F3D2;
  padding: 0px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.ddBestDishCardTopContainerM {
  position: relative;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.DishCardSuperContainer{
  animation: fadeInUpDish 0.6s ease-in-out 0s forwards;
}

@keyframes fadeInUpDish {
  from { 
    opacity: 0;
    -webkit-transform: translate3d(0, 5%, 0);
    transform: translate3d(0, 10%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.DishImageBlur{
  position: absolute;
  left: 0px;
  right: 0;
  top: 0px;
  height: 265px;
  overflow: hidden;
  border-radius: 15px !important;
  width: 100%;
  transition: 0.5s ease;
  z-index: 40;
  background-image: linear-gradient(
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0) 90%
  );
}

.DishImageOverlay{
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  height: 200px !important;
  overflow: hidden;
  border-radius: 20px 20px 0px 0px;
  width: 260px;
  transition: 0.5s ease;
  z-index: 10;
  align-items: center;
  justify-content: center;
  /* display: flex; */
}

.ddDishImageOverlay{
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  height: 200px !important;
  overflow: hidden;
  border-radius: 10px 10px 0px 0px;
  transition: 0.5s ease;
  z-index: 10;
  align-items: center;
  justify-content: center;
  /* display: flex; */
}

.ddDishImageOverlayM{
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  height: 320px !important;
  overflow: hidden;
  border-radius: 20px 20px 0px 0px;
  transition: 0.5s ease;
  z-index: 10;
  align-items: center;
  justify-content: center;
  /* display: flex; */
}

.DishImage {
  height: 260px;
  /* border-radius: 20px 20px 0px 0px; */
  overflow: hidden;
  z-index: 2;
  min-width: 275px;
}

.DishImageM {
  height: 320px;
  border-radius: 20px;
  overflow: hidden;
  z-index: 2;
  width: 100%;
}

.DishImageText{ 
  /* display: inline-block; */
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 190px;
}

.DishTextContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #E8E8E8;
  border-radius: 15px 15px 0px 0px;
  width: 100%;
  box-shadow: 3px 3px 6px #00000029;
  height: 276px;
}

.DishText {
  color: black;
  padding-left: 1vw;
  height: 100px;
  overflow: hidden;
}

.DishDescription{
  max-width: 100%;
  font-family: 'Poppins-Regular';
  color: #000;
  font-size: 12px;
  line-height: 17px;
  height: 34px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 5px 0px;
  color: #5E4B45;
}

.DishRatingsSuperContainer{
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 3px 8px;
  background-color: white;
  border-top-right-radius: 10px;
  box-shadow: 3px 3px 6px #00000029 !important;
}

.DishRatingsSuperContainer:hover{
  background-color: #FFFBD4;
}

.DishRatingsContainer{
  color: #0F9917;
  display: inline-block;
  margin-left: 0px;
  padding: 1px 4px;
  font-size: 14px;
}

.RedDot{
  display: inline-block;
  position: absolute;
  top: 34px;
  left: 15px;
  height: 6px;
  width: 6px;
  background-color: red;
  margin-right: 10px;
  border: 2px solid white;
  outline: 2px solid red;
}

.DishRedDot{
  height: 6px;
  width: 6px;
  background-color: red;
  margin-right: 10px;
  margin-top: 8px;
  border: 2px solid white;
  outline: 2px solid red;
}

.vegTag{
  position: relative;
  color: green;
  border: 2px solid green;
  right: 5px;
}

.nonVegTag{
  position: relative;
  color: red;
  border: 2px solid red;
  right: 5px;
}

.DishName{
  font-family: 'Poppins-SemiBold';
  font-size: 14px;
  line-height: 18px;
}

.DishRestaurant{
  color: #EA5B2F;
  font-family: 'Poppins-Medium';
  font-size: 13px;
  font-weight: bold;
  margin: 3px 0px;
}

.DishRestaurantM{
  color: #EA5B2F;
  font-family: 'Poppins-Medium';
  font-size: 12px;
  font-weight: bold;
  margin: 3px 0px;
}

.LikeButtonContainerSuper{
  position: absolute;
  z-index: 100;
  margin: 0 auto;
  opacity: 1.2;
  top: 10px;
  width: 32px;
  right: 0px; /* Adjust this value to move the positioned div up and down */
  color: #FFF;
  /* display: inline-block; */
  display: flex;
  cursor: pointer;
  float: right;
  margin-right: 15px;
  /* background-color: #EA5B2F; */
}

.LikeButtonContainerSuper_BestDish1{
  position: absolute;
  z-index: 100;
  margin: 0 auto;
  opacity: 1.2;
  top: 40px;
  width: 32px;
  left: 0px; /* Adjust this value to move the positioned div up and down */
  color: #FFF;
  /* display: inline-block; */
  display: flex;
  cursor: pointer;
  float: left;
  margin-left: 15px;
  /* background-color: #EA5B2F; */
}

.LikeButtonContainer{
  display: flex;
  cursor: pointer;
  float: right;
}

.LikeButtonContainer .LikeButton{
  width: 75px;
  height: 75px;
  margin-right: 10px;
  background: url(../images/like_button.png) no-repeat;
  background-position: left;
  background-size: 2900%;
  position: absolute;
  transform: translate(0px, -20px);
  opacity: 0.6;
}

.LikeButton.LikeButtonActive{
  background-position: right;
  animation: animate 2s steps(28) 1;
  opacity: 1;
  /* background-color: #EA5B2F; */
}

.loadingGif{
  position: fixed;
  z-index: 1000;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  background: #FFFFFF;
  height: 100%;
  transition: visibility 2s cubic-bezier(0.39, 0.58, 0.57, 1), opacity 2000ms !important;
}

@keyframes favme-anime {
  0% {
    opacity: 1;
    font-size: 1rem;
    -webkit-text-stroke-color: transparent;
  }
  25% {
    opacity: 0.6;
    color: #fff;
    font-size: 0.8rem;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #dc3232;
  }
  75% {
    opacity: 0.6;
    color: #fff;
    font-size: 1.42875rem;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #dc3232;
  }
  100% {
    opacity: 1;
    font-size: 1.25rem;
    -webkit-text-stroke-color: transparent;
  }
}
@keyframes favme-hover {
  from {
    font-size: 1.42875rem;
  }
  80% {
    font-size: 1.25rem;
  }
}

.favme {
  display: block;
  font-size: 15px;
  width: auto;
  height: auto;
  cursor: pointer;
  box-shadow: none;
  transition: all 0.2s ease;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
  color: #FFFFFF;
  text-shadow: 0 0 10px #EA5B2F;
  margin: 0;
}

.favme.LikeNewButtonActive {
  color: #EA5B2F;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #EA5B2F;
}

/* .favme:hover {
  animation: favme-hover 0.3s infinite alternate;
} */

.favme.is_animating {
  animation: favme-anime 0.3s;
}

.DishRatingsNumber{
  background-image: linear-gradient(to right, rgba(0,0,0,0.6), rgba(0,0,0,0.3));
  font-size: 11px;
  padding: 2px 8px 2px 4px;
}

@keyframes animate{
  0%{
    background-position: left;
  }
  100%{
    background-position: right;
  }
}

.DishCardBottomContainer{
  margin-top: 75px;
  height: 220px;
  background-color: #FFFFFF;
  padding: 15px 5px;
}

.DishCardBottomContainerMobile{
  height: 220px;
  padding: 15px;
  border-right: #F7F3D2 1px solid;
  border-left: #F7F3D2 1px solid;
  border-bottom: #F7F3D2 1px solid;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: #FFFEEF;
}

.ddBestDishCardBottomContainerMobile{
  height: 220px;
  padding: 15px;
}

.DishDetailsRow{
  display: flex;
  flex-direction: row;
  /* min-width: 100%; */
}

.DishCardActions {
  margin-left: 2vw;
  margin-right: 2vw;
  position: relative;
  /* overflow: hidden; */
  margin-bottom: 60px;
}

.ddAddCartBtn{
  background: #FCD844;
  font-family: "Poppins-Medium";
  color: #2B2624;
  font-size: 14px;
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 8px 30px 8px 30px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 100ms ease-in-out;
}

.ddAddCartBtnM2{
  background: #FCD844;
  font-family: "Poppins-Medium";
  color: #2B2624;
  font-size: 3.5vw;
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 2vw 5vw 2vw 5vw;
  border-radius: 50px;
  cursor: pointer;
  transition: all 100ms ease-in-out;
}

.ddAddCartBtnM2Disabled{
  background: #E5E1D2;
  font-family: "Poppins-Medium";
  color: #BFBAA1;
  font-size: 3.5vw;
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 2vw 5vw 2vw 5vw;
  border-radius: 50px;
  cursor: pointer;
  transition: all 100ms ease-in-out;
}

.ddAddCartBtn:hover{
  background: #EA5B2F;
  color: white;
  transition: all 100ms ease-in-out;
}

.ddAddCartBtnM1{
  background: #FCD844;
  font-family: "Poppins-Medium";
  color: #2B2624;
  font-size: 14px;
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 8px 30px 8px 30px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 100ms ease-in-out;
}
.ddAddCartBtnM1:hover{
  background: #EA5B2F;
  color: white;
  transition: all 100ms ease-in-out;
}

.ddPopOverAddCartBtn{
  background: #FCD844;
  font-family: "Poppins-Medium";
  color: #2B2624;
  font-size: 14px;
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 8px 0px;
  width: 180px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  margin-left: -15px;
  margin-top: 10px;
}
.ddPopOverAddCartBtn:hover{
  background: #EA5B2F;
  color: white;
  transition: all 100ms ease-in-out;
}

.ddPopOverAddCartBtnM{
  background: #FCD844;
  font-family: "Poppins-Medium";
  color: #2B2624;
  font-size: 12px;
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 4px 0px 6px 0px;
  width: 140px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 100ms ease-in-out;
}
.ddPopOverAddCartBtnM:hover{
  background: #EA5B2F;
  color: white;
  transition: all 100ms ease-in-out;
}

.ddPopOverIllChooseBtn{
  background: #FFFFFF;
  border: #2B2624 1px solid;
  font-family: "Poppins-Medium";
  color: #2B2624;
  font-size: 14px;
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 8px 0px;
  width: 180px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 100ms ease-in-out;
}

.ddPopOverIllChooseBtnM{
  background: #FFFFFF;
  border: #2B2624 1px solid;
  font-family: "Poppins-Medium";
  color: #2B2624;
  font-size: 12px;
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 6px 0px 5px 0px;
  width: 140px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 100ms ease-in-out;
}

.ddPopOverSelectCustomisation{
  background: #E5E1D2;
  border: #E5E1D2 1px solid;
  font-family: "Poppins-Medium";
  color: #a5a5a5;
  font-size: 14px;
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 8px 0px;
  width: 180px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 100ms ease-in-out;
}

.ddPopOverSelectCustomisationM{
  background: #E5E1D2;
  border: #E5E1D2 1px solid;
  font-family: "Poppins-Medium";
  color: #a5a5a5;
  font-size: 12px;
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 5px 0px;
  width: 140px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 100ms ease-in-out;
}

.ddAddCartBtnDisabled{
  background: #E5E1D2;
  font-family: "Poppins-Medium";
  color: #C4AD48;
  font-size: 14px;
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 8px 30px 8px 30px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 100ms ease-in-out;
}

.ddAddCartBtnDisabledM1{
  background: #E5E1D2;
  font-family: "Poppins-Medium";
  color: #BFBAA1;
  font-size: 14px;
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 8px 30px 8px 30px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 100ms ease-in-out;
}

.ddAddCartBtnM{
  background: #FCD844;
  font-family: "Poppins-Medium";
  color: #2B2624;
  font-size: 14px;
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 5px 20px 5px 20px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 100ms ease-in-out;
}
.ddAddCartBtnM:hover{
  background: #EA5B2F;
  color: white;
  transition: all 100ms ease-in-out;
}

.ddAddCartBtnDisabledM{
  background: #E5E1D2;
  font-family: "Poppins-Medium";
  color: #C4AD48;
  font-size: 14px;
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 5px 8px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 100ms ease-in-out;
}

.ddAddCartBtnIcon{
  font-size: 17px;
  position: relative;
  top: 2px;
  margin-right: 5px;
}

.ddAddCartBtnIconM{
  font-size: 17px;
  position: relative;
  top: 3px;
  margin-right: 5px;
}

.ddDishCardRateButton{
  font-family: "Poppins-Medium";
  font-size: 14px;
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 8px 10px 8px 10px;
  margin-right: 30px;
  margin-left: -10px;
  border-radius: 50px;
  cursor: pointer;
  color: #1E429A;
}

.ddDishCardRateButtonM{
  font-family: "Poppins-Medium";
  font-size: 14px;
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 8px 10px 8px 10px;
  margin-right: 10px;
  margin-left: -10px;
  border-radius: 50px;
  cursor: pointer;
  color: #1E429A;
}

.ddDishCardRateIcon{
  color: #FCD844;
  margin-right: 5px;
  font-size: 17px;
  position: relative;
  top: 3px;
}

.ddOrderDishRateStar-notSelected{
  color: #F8F5DC;
  margin-right: 10px;
  position: relative;
  transition: all 100ms ease-in-out;
}

.ddOrderDishRateStar-Selected{
  color: #73D114;
  margin-right: 10px;
  position: relative;
  transition: all 100ms ease-in-out;
}

.ddOrderDishRateStar-notSelected:hover{
  -webkit-filter: drop-shadow(0px 1px 6px #00000029);
  filter: drop-shadow(0px 1px 6px #00000029);
  transition: all 100ms ease-in-out;
}

.ddRateNumText{
  color: #5E4B45;
  font-family: "Poppins-Medium";
  font-size: 10px;
  position: absolute;
}

.DishCardActionsContainer {
  /* z-index: 10;
  position: relative;
  top: 0px;
  left: 0px; */
  /* height: 40px; */
  /* background-color: white; */
  /* border-radius: 0px 0px 15px 15px; */
  /* width: 100%; */
  /* box-shadow: 3px 3px 6px #00000029; */
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
  margin-bottom: 0px;
  width: 100%;
}

.ddDishCardActionsContainer {
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
  margin-bottom: 0px;
  width: 100%;
  bottom: 20px;
}

.ddDishCardActionsContainerM {
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
  margin-bottom: 0px;
  /* width: 100%; */
  bottom: 20px;
}

.DishCardActionsAfterContainer {
  content: '';
  width: 100px;
  height: 3px;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
  background: #F9D543;
  bottom: 0px;
  transition: 0.5s ease;
  position: absolute;
  z-index: 400;
}

.DishCardActionsText{
  flex: 1;
  margin-top: 10px ;
  padding-bottom: 2px;
  text-align: center;
  font-family: 'Poppins-Medium';
  font-size: 13px;
  width: 50%;
}

.Rate{
  /* border-right: 2px solid #E8E8E8 !important; */
  /* z-index: 100; */
  background-color: #F9D543;
  margin-right: 10px;
  padding: 6px 0px;
  border-radius: 5px;
  font-family: 'Poppins-Medium';
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  
  /* flex: 1 1 auto; */
}

.Rate:hover{
  background-color: #EA5B2F;
  color: white;
  font-size: 15px;
}

.Review{
  margin-left: 15px;
  padding: 6px 0px;
  font-family: 'Poppins-Medium';
  font-size: 14px;
  color: #EA5B2F;
  text-align: center;
  /* flex: 1 1 auto; */
}

.ReviewText{
  color: #EA5B2F;
  font-size: 14px;
}

.ReviewText:hover{
  color: black;
  font-size: 15px;
}

.DishDescriptionReadMore{
  display: inline-block;
  color: black;
  font-style: italic;
  margin-left: 5px;
  font-family: 'Poppins-Medium';
  cursor: pointer;
}

.DishDescriptionReadMoreM{
  display: inline-block;
  color: black;
  font-style: italic;
  /* margin-left: 5px; */
  font-family: 'Poppins-Medium';
  cursor: pointer;
  font-size: 12px;
}

.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown .dropdown-toggle {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;
}

.DropdownList{
  box-shadow: 0px 3px 12px #00000040;
}

.dropdown .dropdown-toggle .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.dropdown-menu {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
  width: 180px;
  position: fixed;
  top: 50px;
  right: 7.5vw;
  z-index: 2000000;
  float: left;
  padding: 5px 10px;
  margin: 2px 0 0;
  font-size: 15px;
  text-align: left;
  list-style: none;
  font-family: 'Poppins-SemiBold';
  color: #fff;
}

.dropdown-menuM {
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
  -ms-animation: fadein 0.5s; /* Internet Explorer */
  -o-animation: fadein 0.5s; /* Opera < 12.1 */
  animation: fadein 0.5s;
  width: 130px;
  position: fixed;
  bottom: 50px;
  right: 7.5vw;
  z-index: 2000000;
  float: left;
  padding: 5px 10px;
  margin: 2px 0 0;
  font-size: 15px;
  text-align: left;
  list-style: none;
  font-family: 'Poppins-SemiBold';
  color: #fff;
}

.dropdown-top-arrow{
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #EA5B2F;
  margin-left: 75px;
}


.dropdown-menu-list {
  z-index: 20000;
  cursor: pointer;
  overflow: hidden;
  padding: 6px 0px;
  font-size: 15px;
}

.tooltip {
  position: relative;
}
.tooltip .tooltip-label {
  position: absolute;
  padding: 0.5em;
  margin-bottom: 1em;
  bottom: -100px;
  left: 230px;
  transform: translateX(-50%);
  min-width: 12em;
  border-radius: 2px;
  background-color: #2F52AE;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.15);
  z-index: 999999999999;
  width: 460px;
  border-radius: 12px;
}
.tooltip .tooltip-label:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border: 0.4em solid transparent;
  border-bottom-color: #2F52AE;
  left: 0%;
  left: 40px;
  z-index: -1;
  transform: translateX(-50%);
  /* transition: top 300ms ease; */
}
.tooltip.is-visible .tooltip-label {
  transform: translateY(0) translateX(-50%);
  opacity: 1;
  visibility: visible;
  /* transition: transform 300ms ease, opacity 300ms, visibility 300ms 0s; */
}
.tooltip.is-visible .tooltip-label:before {
  bottom: 100%;
}
.tooltip.is-hidden .tooltip-label {
  transform: translateY(100%) translateX(-50%);
  opacity: 0;
  visibility: hidden;
  /* transition: transform 300ms ease, opacity 300ms, visibility 300ms 300ms; */
}
.tooltip.is-hidden .tooltip-label:after {
  top: 0;
}
@keyframes fade {
  0% {
    top: 0;
    opacity: 1;
 }
  100% {
    top: -1em;
    opacity: 0;
 }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@media only screen and (max-width: 1000px) {

  .FilterItemsImage{
    height: 50px;
  }

  .FilterItemsText{
    font-size: 14px;
  }

  .OffersCount{
    font-size: 1.0vw;
  }
  
  .OffersDiscount{
    font-size: 1.8vw;
  }
  
  .OffersDescription{
    font-size: 1.2vw;
  }

}

@media only screen and (max-width: 800px) {

  .OffersCount{
    font-size: 1.2vw;
  }
  
  .OffersDiscount{
    font-size: 2vw;
  }
  
  .OffersDescription{
    font-size: 1.4vw;
  }
}

@media only screen and (max-width: 600px) {

  .FilterItemsImage{
    height: 40px;
  }

  .FilterItemsText{
    font-size: 13px;
  }

  .OffersCount{
    font-size: 1.6vw;
  }
  
  .OffersDiscount{
    font-size: 2.4vw;
  }
  
  .OffersDescription{
    font-size: 1.8vw;
  }

  #dishes-login-button-mobile{
    font-size: 12px;
  }

  #dishes-signup-button-mobile{
    font-size: 12px;
    padding: 4px 20px;
  }

  .HomeAddressLocationIcon{
    font-size: 12px !important;
  }

  .PriceArrow{
    transform: translateY(7px);
  }

  .DishCardHeaderText{
    font-size: 16px;
  }

  .VegToggleText{
    font-size: 12px;
    transform: translateY(-4px) !important;
  }

  .VegToggle{
    margin-right: 5px;
    transform: translateY(-4px);
  }

  .VegToggleM{
    margin-right: 5px;
    top: 5px;
  }

  .DishFilters{
    margin-top: 0px;
  }

  .DishesActionsHeader{
    padding: 5px 0px 15px 0px;
  }
}

@media only screen and (max-width: 450px) {

  .OffersCount{
    font-size: 2.0vw;
  }
  
  .OffersDiscount{
    font-size: 2.8vw;
  }
  
  .OffersDescription{
    font-size: 2.2vw;
  }

  .DishesHeader{
    padding-bottom: 5px;
  }

  .HomeAddressBar{
    font-size: 12px;
  }

  .HomeAddressLocationIcon{
    font-size: 14px !important;
  }
  
  .OffersCarouselNextSlide{
    padding: 6px 12px 11px 12px;
    margin-left: 15px;
  }

  .OffersCarouselPrevSlide{
    padding: 6px 12px 11px 12px;
    margin-right: 15px;
  }

  .OffersCount{
    font-size: 14px;
  }

  .OffersDiscount{
    font-size: 14px;
  }

  .OffersDescription{
    font-size: 12px;
  }

  .DishesActions{
    padding-right: 15px;
    padding-left: 15px;
  }

  .filter-button{
    padding: 6px 8px;
  }
}

@media only screen and (max-width: 500px) {

  .HomeDishCardWrapper{
    width: 300px;
  }

  .DishImageOverlay{
    width: 300px;
  }

  .DishImage{
    min-width: 300px;
    height: 300px;
  }

  .OffersCarouselContainer{
    margin-top: 60px;
  }

  .OffersCarouselContainerM{
    margin-top: 60px;
  }

  .DishDescriptionReadMore{
    font-size: 13px;
  }

  .FilterItemsContainer{
    margin-right: 10px; 
    margin-left: 10px;
  }

  .OffersAuthBackground{
    padding: 5px 10px 0px 10px;
  }

  .SearchIcon{
    font-size: 20px !important;
    transform: translateY(3px);
    color: #707070 !important;
  }

  .dropdown-menu{
    width: 130px;
  }

  .dropdown-menu-list {
    font-size: 12px;
    text-align: right;
  }

  .FilterItemsSuper{
    margin-right: 0vw;
  }

  .Location::placeholder{
    font-size: 12px;
  }

  .FilterButton{
    font-size: 12px;
  }

  .tooltip .tooltip-label {
    left: calc(50vw - 20px);
    width: calc(100vw - 40px);
    bottom: -130px;
  }
}


