.PartnerWithUsFormContainer{
    overflow-y: scroll;
    margin-right: 6vw;
    overflow-x: hidden;
    padding: 40px 0px;
    padding-right: 20px;
    padding-bottom: 0px;
}

.PartnerWithUsFormContainer::-webkit-scrollbar{
    width: 10px;
    background: white;
}

.PartnerWithUsFormContainer::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
    
}

.PartnerWithUsLogoContainer{
    padding-top: 40px;
    padding-bottom: 40px;
}

.PartnerWithUsHeader{
    margin: auto;
    font-size: 40px;
    text-align: center;
    letter-spacing: 0.02rem;
    font-family: 'Quicksand-Bold';
    letter-spacing: -1px;
    margin-bottom: 20px;
}

.PartnerWithUsFieldName{
    font-family: 'Poppins-Regular';
    font-size: 13px;
}

.PartnerWithUsFieldContainer{
    margin: 10px 0px;
}

.PartnerWithUsField{
    width: 100%;
    border: #EBEBEB 1px solid;
    background-color: #EBEBEB;
    padding: 10px 15px;
    margin-right: 20px;
    border-radius: 5px;
}

.PartnerWithUsField:focus{
    outline: none;
}

.PartnerWithUsField::placeholder{
    font-size: 11px;
    color: #888888;
}

.PartnerWithUsFieldSuccess{
    font-size: 12px;
    padding-top: 2px;
    color: #008000;
    font-family: 'Poppins-Regular';
    text-align: center;
}

.PartnerWithUsFieldError{
    font-size: 12px;
    padding-left: 5px;
    padding-top: 2px;
    color: #EB622F;
    font-family: 'Poppins-Regular';
}

.PartnerWithUsSubmitButtonContainer{
    margin: auto;
    margin-top: 20px;
    margin-bottom: 15px;
    background-color: #EB622F;
    border-radius: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0px 0px;
    width: 240px;
}

.PartnerWithUsOTPContainer{
    padding: 60px;
    box-shadow: 0px 3px 20px #00000029;
    border-radius: 150px;
    margin: 10px;
}

.PartnerWithUsOTPHeader{
    font-family: 'Quicksand-SemiBold';
    letter-spacing: -1px;
    text-align: center;
    font-size: 20px;
    padding: 5px 0px;
}

.PartnerWithUsOTPSubHeader{
    font-family: 'Poppins-Regular';
    font-size: 13px;
    text-align: center;
    padding: 5px 0px;
}

.PartnerWithUsResendOTP{
    font-size: 13px;
    padding-top: 2px;
    padding-right: 5px;
    font-family: 'Poppins-Regular';
    text-align: right;
    cursor: pointer;
}

.PartnerWithUsThankYouContainer{
    padding: 24px 0px;
}

.PartnerWithUsThankYouHeader{
    font-family: 'Quicksand-Bold';
    letter-spacing: -1px;
    font-size: 32px;
    text-align: center;
    margin: 8px 0px;
}

.PartnerWithUsThankYouSubHeader{
    font-family: 'Quicksand-Regular';
    letter-spacing: -1px;
    font-size: 24px;
    text-align: center;
    margin: 8px 0px;
    color: #EB622F;
}

.PartnerWithUsFieldButton{
    text-align: left !important;
    font-size: 12px;
    font-family: 'Poppins-Regular';
}

.CurrentStepContainer{
    padding: 0px 3px;
}

.CurrentActiveStep{
    height: 6px;
    background-color: #EB622F;
    width: 100%;
    border-radius: 10px;
}

.CurrentNonActiveStep{
    height: 6px;
    background-color: #EBEBEB;
    width: 100%;
    border-radius: 10px;
}

.UploadImageHeader{
    text-align: center;
    margin: 25px auto;
    font-family: 'Poppins-Regular';
    font-size: 13px;
}

.UploadImageSuccessHeader{
    font-family: 'Quicksand-Regular';
    letter-spacing: -1px;
    font-size: 22px;
    margin: 40px auto 20px auto;
    color: #3C8925;
}

.UploadImageFormContainer{
    height:300px; 
    width: 300px; 
    margin: auto;
    background-color: #FAFAFA;
    border: 2px dashed rgba(112, 112, 112, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.UploadRestaurantImageFormContainer{
    height: 250px; 
    width: 600px; 
    margin: auto;
    background-color: #FAFAFA;
    border: 2px dashed rgba(112, 112, 112, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.UploadImageFormCropContainer{
    height: 350px; 
    width: 350px; 
    border: none;
    margin-bottom: 20px;
}

.UploadRestaurantImageFormCropContainer{
    height: 260px; 
    width: 600px; 
    border: none;
    margin-bottom: 20px;
}

.DishPreviewImageContainer{
    height:300px; 
    width: 300px; 
    margin: auto;
    margin-bottom: 20px;
}

.RestaurantPreviewImageContainer{
    height: 170px; 
    width: 600px; 
    margin: auto;
    margin-bottom: 20px;
}

.UploadImageFormFields{
    height:300px; 
    width: 300px; 
}

.RestaurantsPreviewCoverContainer{
    overflow: hidden;
    height: 255px; 
    width: 900px; 
}

.RestaurantsPreviewCoverImage{
    height: 255px; 
    width: 900px; 
}

.RestaurantsPreviewModalContainer{
    max-width: 1000px;
    width: 80%;
    position: relative;
    margin: 30vh auto;
    border-radius: 10px;
    cursor: auto;
    background-color: white;
    display: flex;
    flex-flow: column;
}

.DishPreviewImageOverlay {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    height: 260px !important;
    overflow: hidden;
    border-radius: 20px 20px 0px 0px;
    width: 275px;
    transition: 0.5s ease;
    z-index: 10;
    align-items: center;
    justify-content: center;
}

.DishPreviewCardWrapper{
    margin: 20px auto;
    position: relative;
    border-radius: 10px;
    box-shadow: 2px 2px 10px #aaaaaa;
  }

  .PartnerWithUsBackContainer{
    font-family: "Poppins-Medium"; 
    font-size: 13px;
    transform: translateY(10px);
    background-color: #F2F2F2;
    height: 30px;
    padding: 6px 20px 6px 15px;
    border-radius: 20px;
    cursor: pointer;
    z-index: 200;
}

.PartnerWithUsBackIcon{
    font-size: 14px !important;
    transform: translateY(3px);
}

.DishPreviewImage {
    height: 275px;
    border-radius: 20px 20px 0px 0px;
    overflow: hidden;
    z-index: 2;
    min-width: 275px;
}

.PartnerWithUsFieldHeader{
    margin: 10px 0px 0px 0px;
    font-family: 'Poppins-Medium';
}

.PartnerWithUsFieldSubHeader{
    font-family: 'Poppins-Regular';
    font-size: 12px;
}


@media (max-width: 500px) {

    .PartnerWithUsFormContainer{
        padding: 20px 0px 20px 2vw;
        margin: auto;
        width: 95vw;
    }

    .RestaurantPreviewImageContainer{
        height: 85px; 
        width: 300px; 
    }

    .RestaurantsPreviewCoverContainer{
        height: 85px; 
        width: 300px; 
    }

    .RestaurantsPreviewCoverImage{
        height: 85px; 
        width: 300px; 
    }

    .DishCardPreviewModalContainer{
        width: 340px;
    }

    .RestaurantsPreviewModalContainer{
        width: 320px;
    }

    .UploadRestaurantImageFormContainer{
        height: 175px; 
        width: 300px; 
    }

    .UploadImageFormCropContainer{
        height: 330px; 
        width: 330px; 
    }

    .UploadRestaurantImageFormCropContainer{
        height: 175px; 
        width: 300px; 
    }

    .PartnerWithUsHeader{
        margin-bottom: 10px;
        font-size: 30px;
    }

    .PartnerWithUsFieldSuperContainer{
        padding: 0px 5vw;
    }

    .PartnerWithUsField{
        font-size: 14px;
    }

    .PartnerWithUsOTPContainer{
        padding: 30px 40px;
        border-radius: 80px;
        margin: 40px 10px;
    }

    input:focus, textarea:focus, select:focus{
        outline: none;
    }

    .DishPreviewImage{
        min-width: 300px;
        /* height: 300px; */
    }

}