@font-face {
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-weight: 900;
    src: local('Montserrat-Medium'), 
            url(../fonts/Montserrat/Montserrat-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins-Light';
    font-style: normal;
    font-weight: 900;
    src: local('Poppins-Light'), 
          url(../fonts/Poppins/Poppins-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 900;
    src: local('Poppins-Regular'), 
          url(../fonts/Poppins/Poppins-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 900;
    src: local('Poppins-Medium'), 
            url(../fonts/Poppins/Poppins-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins-SemiBold';
    font-style: normal;
    font-weight: 900;
    src: local('Poppins-SemiBold'), 
            url(../fonts/Poppins/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins-Bold';
    font-style: normal;
    font-weight: 900;
    src: local('Poppins-Bold'), 
            url(../fonts/Poppins/Poppins-Bold.ttf) format('truetype');
}

.SettingsHeader{
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}

.SettingsLeftHeader{
    font-family: 'Poppins-Bold';
    font-size: 20px;
    flex: 1;
    padding-left: 5px;
}

.SettingsRightHeader{
    text-align: right;
    font-size: 16px;
    color: #FF002A;
    font-family: 'Poppins-Regular';
    flex: 1;
    padding-right: 10px;
    cursor: pointer;
} 

.ProfileUpdateContainer{
    background-color: white;
    padding: 15px 2vw 50px 2vw;
    border-radius: 6px;
    margin: 12px 0px;
}

.ProfileUpdateLabel{
    color: #858585;
    font-size: 15px;
    font-family: 'Poppins-Regular';
    margin-top: 25px;
    animation: fadeInUp 1s ease-in-out 0s forwards;
}

.ProfileUpdateField{
    font-family: 'Poppins-SemiBold';
    margin: 12px 0px;
    padding: 6px 0px;
    height: 35px;
    border: 0px;
    font-size: 16px;
    margin-bottom: 2px;
    border-bottom: 1px solid rgba(133,133,133, 0.2);
}

.ProfileUpdateInputField{
    font-family: 'Poppins-SemiBold';
    margin: 12px 0px;
    padding: 5px 0px;
    width: 100%;
    border: 0;
    outline: 0;
    height: 35px;
    background: transparent;
    font-size: 16px;
    margin-bottom: 2px;
    border-bottom: 1px solid rgba(133,133,133, 0.2);
}

.ProfileUpdateInputField::placeholder{
    font-family: 'Poppins-Regular';
    color: rgb(133, 133, 133);
}

.ProfileUpdateError{
    font-size: 13px;
    color: #FF002A;
    font-family: 'Poppins-Regular';
    margin-left: 5px;
}
