  @font-face {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 900;
    src: local('Poppins-Regular'), 
          url(../fonts/Poppins/Poppins-Regular.ttf) format('truetype');
  }
  
  @font-face {
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 900;
    src: local('Poppins-Medium'), 
          url(../fonts/Poppins/Poppins-Medium.ttf) format('truetype');
  }
  
  @font-face {
    font-family: 'Poppins-SemiBold';
    font-style: normal;
    font-weight: 900;
    src: local('Poppins-SemiBold'), 
          url(../fonts/Poppins/Poppins-SemiBold.ttf) format('truetype');
  }
  
  @font-face {
    font-family: 'Poppins-Bold';
    font-style: normal;
    font-weight: 900;
    src: local('Poppins-Bold'), 
          url(../fonts/Poppins/Poppins-Bold.ttf) format('truetype');
  }
  
  @font-face {
    font-family: 'Quicksand-Regular';
    font-style: normal;
    font-weight: 900;
    src: local('Quicksand-Regular'), 
          url(../fonts/Quicksand/Quicksand-Regular.ttf) format('truetype');
  }
  
  @font-face {
    font-family: 'Quicksand-Bold';
    font-style: normal;
    font-weight: 900;
    src: local('Quicksand-Bold'), 
          url(../fonts/Quicksand/Quicksand-Bold.ttf) format('truetype');
  }
  
  @font-face {
    font-family: 'Quicksand-SemiBold';
    font-style: normal;
    font-weight: 900;
    src: local('Quicksand-SemiBold'), 
          url(../fonts/Quicksand/Quicksand-SemiBold.ttf) format('truetype');
  }
  
  @font-face {
    font-family: 'Quicksand-Regular';
    font-style: normal;
    font-weight: 900;
    src: local('Quicksand-Regular'), 
          url(../fonts/Quicksand/Quicksand-Regular.ttf) format('truetype');
  }
  
  @font-face {
    font-family: 'Quicksand-Bold';
    font-style: normal;
    font-weight: 900;
    src: local('Quicksand-Bold'), 
          url(../fonts/Quicksand/Quicksand-Bold.ttf) format('truetype');
  }
  
  @font-face {
    font-family: 'Quicksand-SemiBold';
    font-style: normal;
    font-weight: 900;
    src: local('Quicksand-SemiBold'), 
          url(../fonts/Quicksand/Quicksand-SemiBold.ttf) format('truetype');
  }

#HomeButtonNew{
    font-family: 'Poppins-Medium';
    font-size: 14px;
    text-transform: none;
    padding: 7px 10px;
    margin-right: 10px;
    text-transform: none;
    border-radius: 40px;
    color: #2B2624;
}


#LoginButtonNew{
    font-family: 'Poppins-Medium';
    font-size: 14px;
    border: 1px solid #2B2624;
    box-shadow: none;
    padding: 7px 30px;
    margin-right: 10px;
    text-transform: none;
    border-radius: 30px;
    color: #2B2624;
}

#SignupButtonNew{
    color: #2B2624;
    font-family: 'Poppins-Medium';
    background: #FCD844 0% 0% no-repeat padding-box;
    border: 1px solid #FCD844;
    border-radius: 30px;
    padding: 7px 30px;
    margin-right: 10px;
    font-size: 14px;
    text-transform: none;
}

.HomeTitleNew{
    margin: 80px auto 40px auto;
    font-family: 'Quicksand-Bold';
    font-size: 50px;
    max-width: 640px;
    text-align: center;
    letter-spacing: -2px;
    padding: 0px 20px;
}

.HomeTitleRed{
    color: #EA5B2F;
}

.LocationContainerNew{
    margin: auto;
    display: flex;
    flex-direction: row;
    width: 40vw;
}

.LocationInputContainerNew{
    display: flex;
    flex-direction: row;
}

.LocationInputNew{
    border: white 1px solid;
    background-color: white;
    width: 36vw;
    padding: 15px 5px;
    font-family: 'Montserrat-Regular';
    font-size: 14px;
    color: #686868;
    margin: auto;
}

.LocationInputNew:focus{
    outline: none;
}

.LocationInputNew::placeholder{
    color: #686868;
}

.BurgerContainerNew{
    overflow: hidden;
    height: 360px;
}

.BurgerNew{
    height: 44vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
    transform: translateY(11vw);
}

.WhyDaidish{
    background-color: #EA5B2F;
    display: flex;
    flex-direction: row;
    background-image: url('../images/home_textures_orange1.png');
}

.WhyDaidishMobile{
    background-image: url('../images/home_textures_orange1.png');
}

.BlueContentNew{
    background-image: url('../images/home_textures_blue.png');
}

.YellowContentNew{
    background-image: url('../images/home_textures_orange1.png');
}

.ButtonContentNew:hover{
    background-color: #2B2624 !important;
    color: white !important;
}

.WhyDaidishImage{
    width: 55vw; 
    transform: translate(-150px, 80px);
}

.WhyDaidishContent{
    padding-top: 15vw;
    transform: translateX(-15vw);
}
  
.WhyDaidishHeader{
    color: white;
    font-family: 'Quicksand-Bold';
    letter-spacing: -1px;
    font-size: 4.0vw;
    opacity: 1;
}
  
.WhyDaidishDetails{
    margin-top: 20px;
    color: white;
    font-family: 'Poppins-Medium';
    width: 40vw;
    font-size: 1.6vw;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
}

.AuthenticationNew{
    z-index: 100;
    transform: translate3d(0, 0, 100);
    /* height: auto; */
    transition: 0s ease-in-out;
    background-color: white;
    clip-path: none; 
    overflow-x: hidden;
}

.AuthenticationWrapperNew{
    overflow: hidden;
    width: 35vw;
    border-radius: 20px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 80px #F5F0D9;
    border: 1px solid #F8F3DD;
    margin: 0px 5vw;
    padding: 40px 0px;
}