@font-face {
  font-family: 'Montserrat-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat-Regular'), 
        url(../fonts/Montserrat/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat-SemiBold'), 
        url(../fonts/Montserrat/Montserrat-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-ExtraBold';
  font-style: normal;
  font-weight: 900;
  src: local('Montserrat-ExtraBold'), 
        url(../fonts/Montserrat/Montserrat-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'ProximaNova-Light';
  font-style: normal;
  font-weight: 600;
  src: local('ProximaNova-Light'), 
        url(../fonts/ProximaNova/ProximaNova-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'ProximaNova-Medium';
  font-style: normal;
  font-weight: 600;
  src: local('ProximaNova-Medium'), 
        url(../fonts/ProximaNova/ProximaNova-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'ProximaNova-SemiBold';
  font-style: normal;
  font-weight: 900;
  src: local('ProximaNova-SemiBold'), 
        url(../fonts/ProximaNova/ProximaNova-Semibold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Regular';
  font-style: normal;
  font-weight: 900;
  src: local('Poppins-Regular'), 
        url(../fonts/Poppins/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Medium';
  font-style: normal;
  font-weight: 900;
  src: local('Poppins-Medium'), 
        url(../fonts/Poppins/Poppins-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  font-style: normal;
  font-weight: 900;
  src: local('Poppins-SemiBold'), 
        url(../fonts/Poppins/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Bold';
  font-style: normal;
  font-weight: 900;
  src: local('Poppins-Bold'), 
        url(../fonts/Poppins/Poppins-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Quicksand-Regular';
  font-style: normal;
  font-weight: 900;
  src: local('Quicksand-Regular'), 
        url(../fonts/Quicksand/Quicksand-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Quicksand-Bold';
  font-style: normal;
  font-weight: 900;
  src: local('Quicksand-Bold'), 
        url(../fonts/Quicksand/Quicksand-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Quicksand-SemiBold';
  font-style: normal;
  font-weight: 900;
  src: local('Quicksand-SemiBold'), 
        url(../fonts/Quicksand/Quicksand-SemiBold.ttf) format('truetype');
}

/* .Header{
  background: linear-gradient(55deg, #FFFFFF 38%, #F2DE1D 38%);
} */

.Logo{
  width: 15vw;
  font-family: "Montserrat-ExtraBold";
  font-size: 28px;
  padding-left: 8vw;
}

.LogoHome{
  padding-left: 2vw;
}

.LogoImage{
  height: 65px;
  padding-top: 15px;
}

.LogoImageMargin{
  margin-left: 3vw;
}

.AuthBackground{
  padding: 20px;
  padding-left: 60px;
}

#login-button{
  font-family: 'Poppins-Medium';
  background-color: rgba(0,0,0,0);
  box-shadow: none;
  padding: 6px 25px;
  margin-right: 10px;
  color: #404040; 
  font-size: 16px;
  text-transform: capitalize;
}

#login-button-mobile{
  font-family: 'Poppins-Medium';
  background-color: rgba(0,0,0,0);
  box-shadow: none;
  padding: 6px 10px;
  margin-right: 10px;
  color: #404040; 
  font-size: 14px;
  text-transform: capitalize;
}

#login-button:hover{
  color: #EA5B2F;
}

#signup-button, #signup-button-content {
  color: #FFFFFF;
  font-family: 'Poppins-Medium';
  background: #EA5B2F 0% 0% no-repeat padding-box;
  border-radius: 30px;
  padding: 12px 30px;
  margin-right: 10px;
  font-size: 16px;
  text-transform: capitalize;
  border: 1px solid #EA5B2F;
}

#signup-button-mobile, #signup-button-content-mobile {
  color: #FFFFFF;
  font-family: 'Poppins-Medium';
  background: #EA5B2F 0% 0% no-repeat padding-box;
  border-radius: 30px;
  padding: 6px 25px;
  margin-right: 10px;
  font-size: 14px;
  text-transform: capitalize;
  border: 1px solid #EA5B2F;
  margin: 10px 0px;
}

#signup-button:hover{
  color: #EA5B2F;
  background: #F9D543 0% 0% no-repeat padding-box;
  border: 1px solid #EA5B2F;
}

#signup-button-content:hover{
  color: #EA5B2F;
  background: #F3F3F3 0% 0% no-repeat padding-box;
  border: 1px solid #EA5B2F;
}

#signup-button-content-mobile:hover{
  color: #EA5B2F;
  background: #F3F3F3 0% 0% no-repeat padding-box;
  border: 1px solid #EA5B2F;
}

.BurgerContainer{
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 0vw;
}

.Burger{
  width: 40vw;
  display: block;
  margin-left: auto;
  margin-right: auto;
  z-index: 200;
}

.Ratings{
  font-size: 3vw !important;
  color: #F9D543 !important;
}

.AuthenticationWrapper{
  filter: drop-shadow(-5px 5px 6px #00000029);
  position: fixed;
  right: 0px;
  top: 0px;
}

.LoginWelcomeOTP{
  margin-top: 0vh !important;
}

.RegisterWelcomeOTP{
  margin-top: 0px !important;
}

.OTPError{
  margin-bottom: 15px;
  font-size: 13px !important;
  color: red;
  font-family: 'Poppins-Regular';
}

.Authentication, .HomeAuthentication{
  z-index: 100;
  height: 45vw;
  display: flex;
  justify-content: center;
  transition: 0.5s ease-in-out;
  background-color: #F9D543;
  clip-path:polygon(0 0, 100% 0, 100% 100%, 16vw 100%);
}

.HomeAuthentication{
  clip-path: none;
  background-color: white;
  height: unset;
  flex-direction: column;
}

.AuthFormContainer, .HomeAuthFormContainer{
  /* margin-left: 15vw; */
  width: 45vw;
  display: flex;
  justify-content: center;
}

.HomeAuthFormContainer{
  width: 100%;
}

.AuthFormButtons{
  padding: 6px;
  margin: 0px 1.2vw;
  position: relative;
  cursor: pointer;
  font-size: 1.8vw;
  font-family: 'ProximaNova-SemiBold';
}

.AuthForm, .HomeAuthForm{
  width: 45vw;
  margin-top: 2vw;
  margin: auto;
}

.HomeAuthForm{
  width: 100%;
  overflow: hidden;
}

.AuthFormFieldContainer{
  background-color: white; 
  border-radius: "5px"; 
  margin: 5px auto; 
  width: 25vw;
}

.AuthFormField, .HomeAuthFormField{
  border: #F9D543 1px solid;
  border-radius: 5px;
  background-color: #FFFFFF;
  width: 25vw;
  padding: 10px 2px;
  font-family: 'Poppins-Medium';
  font-size: 15px;
  padding-left: 1.5vw;
  color: black;
  opacity: 0.8;
}

.HomeAuthFormField{
  border: #ECECEC 1px solid !important;
  background-color: #ECECEC;
  width: 300px;
  border-radius: 5px !important;
}

.AuthFormField::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #888888;
  font-family: 'Poppins-Medium';
  opacity: 1; /* Firefox */
  font-size: 12px;
}

.AuthFormField:focus{
  outline: none;
}

.HomeAuthFormField:focus{
  outline: none !important;
}

.AuthFormError{
  font-size: 11px;
  color: red;
  display: inline-block;
  font-family: 'Poppins-Regular';
  width: 25vw;
  margin: auto;
}

.PasswordWrapper{
  position: relative;
}

.PasswordToggle{
  position: absolute;
  top: 15px;
  right: 10px;
}

.ForgotPasswordContainer{
  width: 100%;
  margin: 10px 0px;
  text-align: center;
}

.ForgotPassword{
  font-size: 12px;
  font-family: 'Poppins-Regular';
}

.HomeForgotPasswordContainer{
  width: 100%;
  margin: 10px 0px;
  text-align: center;
}

.HomeForgotPassword{
  font-size: 12px;
  font-family: 'Poppins-Regular';
}

.AuthFormSubmitButtonContainer{
  margin: auto;
  margin-top: 20px;
  width: 25vw;
  background-color: #EA5B2F;
  border-radius: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 2px 0px;
}

.HomeAuthFormSubmitButtonContainer{
  margin: auto;
  margin-top: 15px;
}

.AuthFormSubmitButton{
  margin: auto;
  padding: 8px 0px;
  color: white;
  font-size: 16px;
  font-family: 'Poppins-Medium';
}

.AuthFormToggleContainer{
  text-align: center;
  margin-top: 15px;
}

.AuthFormToggle{
  font-size: 13px;
  font-family: 'Poppins-Regular';
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input {-webkit-appearance: none !important;}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* body{
  transform: translateX(10px);
} */

/* body{
  width: 120%;
}

* {
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
} */

.CloseModalWrapper{
  z-index: 150;
  position: fixed;
  right: 2vw;
  top: 2vw;
}


.CloseModal{
  font-size: 2vw !important;
  animation: fadeIn 0.5s;
  animation-fill-mode: forwards;
  /* visibility: hidden; */
  cursor: pointer;
}

@keyframes fadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.HeaderDescription{
  color: black;
  letter-spacing: -0.1rem;
  width: 35vw;
  margin-left: 1.2vw;
}

.topText{
  margin-top: 0px;
  margin-left: 2vw;
  font-size: 3.6vw;
  font-family: 'Quicksand-Bold';
}

.bottomText{
  width: 35vw;
  font-family: 'Poppins-Medium';
  font-size: 1.1vw;
  font-weight: bold;
  margin-bottom: 20px;
  letter-spacing: 0.2px;
}

.LocationContainer, .HomeLocationContainer{
  width: 27vw;
  margin-left: 3.2vw;
  background-color: white;
  border: white 1px solid !important;
  border-radius: 30px;
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 12px #00000052;
  opacity: 1;
  z-index: 400 !important;
}

.HomeLocationContainer{
  width: 250px;
  /* box-shadow: none; */
  margin: 0px 20px;
}

.Location{
  border: white 1px solid !important;
  padding-right: 10px;
  background-color: white;
  width: 30vw;
  padding: 12px 5px;
  font-family: 'Montserrat-Regular';
  font-size: 14px;
  padding-left: 1.5vw;
  color: #686868;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  font-family: 'Montserrat-Regular';
  font-size: 14px;
  opacity: 1; /* Firefox */
}

.Location:focus{
  outline: none;
}

.LocationIconContainer{
  background-color: white;
  z-index: 40;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.LocationIcon{
  margin-right: 0.6vw;
  text-align: center;
  vertical-align: middle;
  background-color: #EA5B2F;
  border-radius: 100%;
  display:inline-block;
  padding: 2px 5px;
  /* -webkit-box-shadow: 0px 0px 3px 3px #EA5B2F;  
  -moz-box-shadow: 0px 0px 3px 3px #EA5B2F;  
  box-shadow: 0px 0px 3px 3px#EA5B2F; */
  z-index: 40;
  cursor: pointer;
}

.GPSIcon{
  color: white !important;
  padding-top: 10px !important;
  margin: auto;
  position: relative;
  bottom: 4px;
  font-size: 28px !important;
  z-index: 40;
}

.AutoSuggestContainer{
  animation: cssAnimation 0s 0.3s forwards;
  visibility: hidden;
}

@keyframes cssAnimation {
  to   { visibility: visible; }
}

.Tagline{
  background-color: #FFFFFF;
}

.TaglineContent{
  padding-top: 15vh;
  padding-bottom: 15vh;
}

.TaglineHeader{
  color: #1F1F1F;
  font-family: 'Quicksand-Bold';
  letter-spacing: -1px;
  font-size: 3.2vw;
  opacity: 1;
}

.TaglineDetails{
  margin-top: 20px;
  font-family: 'Poppins-Regular';
  color: black;
  width: 400px;
  font-size: 1.1vw;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.Description {
  padding-left: 5vw;
  padding-right: 5vw;
}

.DescriptionImageContainer{
  width: 16vw;
  height: 18vw;
  border-radius: 5vw;
  background-color: white;
  overflow: hidden;
  margin-bottom: 20px;
}

.DescriptionImage{
  width: 15vw;
  margin: 1vw;
}

.DescriptionTaglineHeader{
  color: #1F1F1F;
  font-family: 'Quicksand-SemiBold';
  letter-spacing: -1px;
  font-size: 1.5vw;
  text-align: center;
  opacity: 1;
  width: 25vw;
}

.DescriptionTaglineDetails{
  margin-top: 5px;
  color: black;
  font-family: 'Poppins-Regular';
  width: 25vw;
  font-size: 1.1vw;
  text-align: center;
}

.CurveImageContainer{
  height: 28vw;
  overflow: hidden;
}

.CurveImage{
  width: 100vw;
}

.GreyContainer{
  background-image: linear-gradient(180deg, #F3F3F3 84.9%, #F9D543 84.9%);
  padding-bottom: 3vw;
  overflow: hidden;
}

.YellowContainer{
  background-color: #F9D543;
  padding-top: 10px;
  padding-bottom: 5vw;
}

.ContentDescription{
  padding-left: 10vw;
}

.ContentDescriptionHeader{
  color: #1F1F1F;
  font-family: 'Quicksand-SemiBold';
  letter-spacing: -1px;
  font-size: 2.5vw;
  opacity: 1;
  width: 35vw;
}

.ContentDescriptionText{
  color: #fafafa;
  font-family: 'Quicksand-SemiBold';
  letter-spacing: -1px;
  opacity: 1;
  text-align: center;
}

.ContentDescriptionDetails{
  margin-top: 20px;
  margin-bottom: 20px;
  color: black;
  font-family: 'Poppins-Regular';
  width: 35vw;
  font-size: 1.1vw;
}

.HexagonImageContainer{
  overflow: hidden;
}

.HexagonImage{
  width: 41.67vw;
}

.YellowText{
  padding-left: 5vw;
}

.OffersCard{
  height: 100; 
  background: '#DDD'; 
  border-radius: '8px'; 
  padding: '40px';
}

@media (max-width: 500px) {
  .TaglineContent {
    padding-top: 30px;
    padding-bottom: 10px;
  }

  .HexagonImage{
    width: 41.67vw;
  }

  .TaglineHeader {
    font-size: 20px;
    margin-top: 20px;
  }

  .TaglineDetails{
    margin-top: 5px;
    font-size: 12px;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 25px;
  }

  .DescriptionImageContainer{
    width: 32vw;
    height: 36vw;
    margin-bottom: 5px;
  }

  .DescriptionImage{
    width: 30vw;
    margin: 2vw;
  }

  .DescriptionTaglineHeader{
    font-size: 20px;
    width: 75vw;
  }

  .DescriptionTaglineDetails{
    font-size: 12px;
    width: 75vw;
    margin-bottom: 25px;
  }

  .ContentDescriptionHeader{
    font-size: 18px;
    width: 55vw;
    line-height: 20px;
  }

  .ContentDescription{
    padding-left: 30px;
  }

  .ContentDescriptionDetails{
    font-size: 12px;
    width: 50vw;
  }

  .YellowText{
    padding-left: 0px;
    padding-bottom: 30px;
  }
  
  .GreyContainer{
    overflow: visible;
    padding-top: 30px;
    padding-bottom: 80px;
  }

  .FooterLogoImage{
    margin-top: 20px;
    margin-bottom: 5px;
  }

  .HeaderDescription{
    letter-spacing: 0rem;
    width: 100vw;
    margin-left: 0;
    padding-top: 40px;
  }

  .topText{
    font-size: 28px;
    margin-left: 0;
    margin: auto;
    text-align: center;
  }

  .bottomText{
    font-size: 18px;
    margin: auto;
    padding: 0px 15vw;
    text-align: center;
  }

  .LocationContainer{
    width: 76vw;
    margin-left: 12vw;
    margin-top: 15px;
  }
  
  .Location::placeholder{
    padding-left: 0px;
    font-family: 'Poppins-Regular';
  }
  
  .Location{
    padding: 8px 10px;
  }

  .LocationIconContainer{
    padding-bottom: 3px;
    margin-right: 5px;
    padding-top: 4px;
  }

  .GPSIcon{
    padding-top: 6px !important;
    transform: translateY(1px);
  }

  .AuthenticationWrapper{
    position: static;
    filter: drop-shadow(0px 0px 0px #00000029);
    overflow: hidden;
  }

  .Authentication{
    z-index: 100;
    transform: translate3d(0, 0, 100);
    /* height: auto; */
    transition: 0s ease-in-out;
    background-color: white;
    clip-path: none; 
    overflow: scroll;
    display: block;
    display: none;
  }

  .AuthFormContainer{
    width: 100vw;
    padding: 0px 15px;
  }

  .AuthFormButtons{
    margin-left: 0px;
    margin: 30px 0px 0px 0px;
    font-size: 20px;
  }

  .AuthForm{
    width: 100vw;
    padding: 20px 20px;
  }

  .AuthFormFieldContainer{
    border-radius: "5px"; 
    margin: 5px auto; 
    width: unset;
  }

  .AuthFormField{
    width: 100%;
    border: #ECECEC 1px solid !important;
    background-color: #ECECEC;
    padding: 10px 15px;
  }

  .AuthFormError{
    width: unset;
  }

  .AuthFormSubmitButtonContainer{
    width: 80vw;
  }

  .ForgotPasswordContainer{
    width: auto;
  }

  .CloseModalWrapper{
    position: relative;
    /* float: right; */
    background-color: #F9D543;
  }
  
  
  .CloseModal{
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 22px !important;
    animation: fadeIn 0s;
    animation-fill-mode: forwards;
    /* visibility: hidden; */
    cursor: pointer;
  }

  .AutoSuggestContainer{
    animation: cssAnimation 0s 0s forwards;
    visibility: hidden;
  }

  .HomeLocationContainer{
    width: 120px;
  }

  .LoginWelcomeOTP, .RegisterWelcomeOTP{
    margin-top: 20px !important;
  }
  
}