.honeycomb {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  width: 70vw;
  max-width: 900px;
  padding: 0 10vw;
  margin: auto;
  padding: 0;
  transform: translateY(50px);
}
.honeycomb-cell {
  -webkit-box-flex: 0;
  flex: 0 1 250px;
  max-width: 100px;
  height: 55.25px;
  margin: 1vh 10px 40px;
  position: relative;
  padding: 0.5em;
  text-align: center;
  z-index: 10000;
}
.honeycomb-cell__title {
  position: absolute;
  padding: 0px 5px;
  top: 0%;
  left: 0%;
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  word-break: break-word;
  color: #000;
  font-weight: 700;
  font-size: 12px;
  font-family: 'Poppins-SemiBold';
  -webkit-transition: opacity 350ms;
  transition: opacity 350ms;
  z-index: 10000;
  text-align: center;
}
.honeycomb-cell__title > small {
  font-weight: 300;
  margin-top: 0.25em;
}
/* .honeycomb-cell__image {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
} */
.honeycomb-cell-before, .honeycomb-cell-after {
  content: "";
}
.honeycomb-cell-before, .honeycomb-cell-after, .honeycomb-cell__image, .honeycomb-cell-overlay {
  top: -50%;
  left: 0;
  width: 100%;
  height: 200%;
  display: block;
  position: absolute;
  cursor: pointer;
  -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
          clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  z-index: -1;
}

.honeycomb-cell__image{
  z-index: 100;
  opacity: 0.75;
  width: 60%;
  height: 120%;
  transform: translate(65%, 48%);
}
.honeycomb-cell-before {
  background:rgba(256,256,256, 0.1);
  -webkit-transform: scale(1.055);
          transform: scale(1.055);
}

.honeycomb-cell-overlay{
  opacity: 0;
  z-index: 10000000000;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.2) 
  );
}

.honeycomb-cell-after {
  /* background: rgb(0,0,0); */
  /* opacity: 0.5; */
  margin-right: -4px;
  -webkit-transition: opacity 350ms;
  transition: opacity 350ms;
}


@media (min-width: 300px) and (max-width: 600px) {

  .honeycomb {
    padding: 0px!important;
    transform: translateY(30px);
    width: unset;
    max-width: 324px !important;
    margin: auto !important;
  }

  .honeycomb-cell {
    max-width: 60px;
    height: 33.15px;
    margin: 1vh 10px 25px;
    font-size: 11px;
  }

  .honeycomb-cell:nth-child(n + 1){
    margin-left: 0px;
  }

  .honeycomb-cell:nth-child(20n + 1), .honeycomb-cell:nth-child(20n + 8), .honeycomb-cell:nth-child(20n + 15){
    margin-left: 20px;
  }

  .honeycomb-cell:nth-child(20n + 5), .honeycomb-cell:nth-child(20n + 12){
    margin-left: 37px;
  }

  .honeycomb-cell:nth-child(20n + 19){
    display: none;
  }
  
  .PreferenceListHeader{
    margin-top: 30px !important;
    font-size: 14px !important;
    max-width: 40vw !important;
  }

  .PreferenceSubmitButton{
    font-size: 14px !important;
    padding: 10px 30px !important;
  }

}


@media (min-width: 600px) and (max-width: 685px) {
  .honeycomb-cell:nth-child(3n + 1) {
    margin-left: 70px;
  }
  .honeycomb-cell:nth-child(3n + 2) {
    margin-right: 70px;
  }
  .honeycomb-cell:nth-child(3n + 4) {
    margin-left: 10px;
  }
  .honeycomb-cell:nth-child(3n + 5) {
    margin-right: 10px;  
  }
  .honeycomb-cell:nth-child(3n + 8) {
    display: none;
  }
  .honeycomb-cell:nth-child(3n + 9) {
    display: none;
  }
  .honeycomb-cell:nth-child(3n + 10) {
    display: none;
  }
}

@media (min-width: 685px) and (max-width: 857px) {
  .honeycomb-cell:nth-child(4n + 1) {
    margin-left: 70px;
  }
  .honeycomb-cell:nth-child(4n + 3) {
    margin-right: 70px;
  }
  .honeycomb-cell:nth-child(4n + 5) {
    margin-left: 10px;
  }
  .honeycomb-cell:nth-child(4n + 7) {
      margin-right: 10px;  
  }
  .honeycomb-cell:nth-child(4n + 11) {
    display: none;
  }
  .honeycomb-cell:nth-child(4n + 12) {
    display: none;
  }
  .honeycomb-cell:nth-child(4n + 13) {
    display: none;
  }
  .honeycomb-cell:nth-child(4n + 14) {
    display: none;
  }
}

@media (min-width: 857px) and (max-width: 1029px) {
  .honeycomb-cell:nth-child(5n + 1) {
    margin-left: 70px;
  }
  .honeycomb-cell:nth-child(5n + 4) {
    margin-right: 70px;
  }
  .honeycomb-cell:nth-child(5n + 6) {
    margin-left: 10px;
  }
  .honeycomb-cell:nth-child(5n + 9) {
      margin-right: 10px;  
  }
  .honeycomb-cell:nth-child(5n + 14) {
    display: none;
  }
  .honeycomb-cell:nth-child(5n + 15) {
    display: none;
  }
  .honeycomb-cell:nth-child(5n + 16) {
    display: none;
  }
  .honeycomb-cell:nth-child(5n + 17) {
    display: none;
  }
  .honeycomb-cell:nth-child(5n + 18) {
    display: none;
  }
}

@media (min-width: 1029px) and (max-width: 1200px) {
  .honeycomb-cell:nth-child(6n + 1) {
    margin-left: 70px;
  }
  .honeycomb-cell:nth-child(6n + 5) {
    margin-right: 70px;
  }
  .honeycomb-cell:nth-child(6n + 7) {
    margin-left: 10px;
  }
  .honeycomb-cell:nth-child(6n + 11) {
      margin-right: 10px;  
  }
  .honeycomb-cell:nth-child(6n + 17) {
    display: none;
  }
  .honeycomb-cell:nth-child(6n + 18) {
    display: none;
  }
  .honeycomb-cell:nth-child(6n + 19) {
    display: none;
  }
}

@media (min-width: 1200px) {
  .honeycomb-cell:nth-child(7n + 1) {
    margin-left: 70px;
  }
  .honeycomb-cell:nth-child(7n + 6) {
    margin-right: 70px;
  }
  .honeycomb-cell:nth-child(7n + 8) {
    margin-left: 10px;
  }
  .honeycomb-cell:nth-child(7n + 13) {
      margin-right: 10px;  
  }
}

@media (min-width: 300px) {
  .honeycomb-cell:nth-child(n + 20) {
    display: none;
  }
}

@media (min-width: 600px){
  .honeycomb-cell:hover .honeycomb-cell__title {
    opacity: 1;
    font-size: 15px;
  }

  .honeycomb-cell:hover .honeycomb-cell-after {
    opacity: 1;
  }
  
  .honeycomb-cell:hover .honeycomb-cell__image {
    opacity: 0.5;
  }
  
  .honeycomb-cell:hover .honeycomb-cell-overlay {
    opacity: 1;
  }
}