.TncContainer{
    padding: 40px 10vw;
    padding-top: 70px;
    z-index: 100;
    line-height: 24px;
    text-align: justify;
    text-justify: inter-word;
    letter-spacing: 0.03rem;
    font-size: 16px;
    text-indent: 2vw;
}

.TncBold{
    font-weight: bold;
}

.TncTitle{
    text-align: center;
    margin: 40px 0px;
    font-weight: bold;
    font-size: 24px;
}

.TncIntro{
    padding: 20px 0px;
}

.TncSubIntro{
    padding: 10px 0px;
}

.TncAgreement{
    font-size: 18px;
}

.TncSectionContainer{
    padding: 10px 0px;
}

.TncSectionTitle{
    margin: 20px 0px;
    font-weight: bold;
    font-size: 18px;
    text-align: left;
    text-indent: 0vw;
}

.TncSectionContent{
    margin: 30px 0px;
    margin-left: 5vw;
}

.TncSectionSubContentContainer{
    margin: 20px 0px;
    margin-left: 2vw;
    text-indent: 0vw;
    display: flex;
    flex-direction: row;
}

.TncSectionSubContentTitle{
    margin-right: 2vw;
    font-weight: bold;
}

.TncBulletPoint{
    margin: 5px 0px;
    margin-left: 1vw;
}

.TncExtraSpace{
    height: 5px;
}

@media (max-width: 500px) {

    .TncContainer{
        padding: 40px 6vw;
        padding-top: 70px;
        font-size: 12px;
    }

    .TncTitle{
        font-size: 20px;
        margin-bottom: 20px;
    }

    .TncAgreement{
        font-size: 12px;
    }

    .TncSectionContent{
        margin: 15px 0px;
        margin-left: 5vw;
    }

    .TncSectionTitle{
        font-size: 14px;
    }

    .TncSectionSubContentContainer{
        margin: 10px 0px;
        margin-left: 2vw;
    }

    .TncBulletPoint{
        margin: 3px 0px;
        margin-left: 1vw;
    }

    .TncExtraSpace{
        height: 3px;
    }

}