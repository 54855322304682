@font-face {
    font-family: 'Montserrat-Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Montserrat-Regular'), 
          url(../fonts/Montserrat/Montserrat-Regular.ttf) format('truetype');
  }

@font-face {
    font-family: 'Montserrat-SemiBold';
    font-style: normal;
    font-weight: 700;
    src: local('Montserrat-SemiBold'), 
          url(../fonts/Montserrat/Montserrat-SemiBold.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-weight: 900;
    src: local('Montserrat-Bold'), 
          url(../fonts/Montserrat/Montserrat-Bold.ttf) format('truetype');
  }

@font-face {
    font-family: 'Montserrat-ExtraBold';
    font-style: normal;
    font-weight: 900;
    src: local('Montserrat-ExtraBold'), 
          url(../fonts/Montserrat/Montserrat-ExtraBold.ttf) format('truetype');
  }
  
@font-face {
    font-family: 'ProximaNova-Regular';
    font-style: normal;
    font-weight: 600;
    src: local('ProximaNova-Regular'), 
            url(../fonts/ProximaNova/ProximaNova-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'ProximaNova-SemiBold';
    font-style: normal;
    font-weight: 600;
    src: local('ProximaNova-SemiBold'), 
            url(../fonts/ProximaNova/ProximaNova-Semibold.ttf) format('truetype');
}

@font-face {
    font-family: 'Raleway-Regular';
    font-style: normal;
    font-weight: 900;
    src: local('Raleway-Regular'), 
          url(../fonts/Raleway/Raleway-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Raleway-Medium';
    font-style: normal;
    font-weight: 900;
    src: local('Raleway-Medium'), 
          url(../fonts/Raleway/Raleway-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Quicksand-Regular';
    font-style: normal;
    font-weight: 900;
    src: local('Quicksand-Regular'), 
          url(../fonts/Quicksand/Quicksand-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Quicksand-Medium';
    font-style: normal;
    font-weight: 900;
    src: local('Quicksand-Medium'), 
          url(../fonts/Quicksand/Quicksand-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Quicksand-Bold';
    font-style: normal;
    font-weight: 900;
    src: local('Quicksand-Bold'), 
            url(../fonts/Quicksand/Quicksand-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Quicksand-SemiBold';
    font-style: normal;
    font-weight: 900;
    src: local('Quicksand-SemiBold'), 
            url(../fonts/Quicksand/Quicksand-SemiBold.ttf) format('truetype');
}

body{
    -webkit-tap-highlight-color: transparent;
}

.Footer{
    padding: 1vw 4vw 3vw 4vw;
    background-color: #2B2624;
    overflow: hidden;
}

.FooterLogo{
    /* width: 15vw; */
    font-family: "Montserrat-ExtraBold";
    font-size: 28px;
    color: #FFFFFF;
    padding-bottom: 10px;
}

.FooterColumn{
    padding-bottom: 40px;
    border-bottom: 1px rgba(255, 255, 255, 0.2) solid;
}

.FooterColumnHeader{
    margin-bottom: 15px;
    font-family: "Poppins-Regular";
    color: #EA5B2F;
    font-size: 18px;
    font-weight: bold;
}

.FooterColumnItem{
    margin: 4px 0px;
    font-family: "Poppins-Regular";
    color: #dbdbdb;
    font-size: 14px;
}

.mail-icon{
    font-size: 16px !important;
    color: white;
    vertical-align: middle;
}

.MenuCard {
    box-shadow: 1px #00000038; 
    position: fixed; 
    max-height: 360px; 
    width: 300px; 
    z-index: 1000;
    padding: 20px; 
    border-radius: 10px; 
    bottom: 75px; 
    right: 15px; 
    background-color: #3356AA; 
    color: white;
    overflow: scroll;
    box-shadow: 5px 5px 12px #00000052;
}

.MenuCard::-webkit-scrollbar {
    display: none;
}

.Subscribe{
    font-size: 16px;
}
  
.Mail{
    border: #444444 1px solid !important;
    border-radius: 20px;
    background-color: #444444;
    height: 22px;
    padding: 15px 5px;
    width: 100%;
    font-family: 'Poppins-Regular';
    font-size: 13px;
    padding-left: 15px;
    padding-right: 8px;
    margin: 10px 0px;
    color: #FFFFFF;
    height: 40px;
}

.Mail::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #FFFFFF;
    font-family: 'Poppins-Regular';
    opacity: 0.42;
    padding: 0px 5px;
    font-size: 13px; /* Firefox */
}

.Mail:focus {
    outline: none;
}

#mail-submit {
    color: #FFFFFF;
    font-family: 'Poppins-Regular';
    background: #EA5B2F 0% 0% no-repeat padding-box;
    border-radius: 20px;
    padding: 8px 40px;
    font-size: 14px;
    text-transform: capitalize;
    text-align: center;
    cursor: pointer;
}

.SocialMediaIconsContainer {
    margin-top: 30px;
    margin-bottom: 20px;
}

.SocialMediaIcon{
    width: 38px;
    height: 38px;
    margin: 0px 10px;
    border-radius: 36px;
}

.Copyright{
    font-size: 12px;
    color: #FFFFFF;
    margin-bottom: 10px;
    font-family: 'Poppins-Regular';
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.RatingModal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9999999999;
    opacity:1;
    -webkit-transition: opacity 100ms ease-in;
    -moz-transition: opacity 100ms ease-in;
    transition: opacity 100ms ease-in;
    pointer-events: auto;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    height: 100vh;
    width: 100vw;
}

.CustomisationModal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9999999999;
    opacity:1;
    -webkit-transition: opacity 100ms ease-in;
    -moz-transition: opacity 100ms ease-in;
    transition: opacity 100ms ease-in;
    pointer-events: auto;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    height: 100vh;
    width: 100vw;
}

.ddCustomisationModalLayout{
    position: relative; 
    width: 100%;
    height: 500px;
    overflow-y: auto;
    margin-bottom: 20px;
    padding: 40px 60px;
}

.ddCustomisationModalLayout::-webkit-scrollbar {
    width: 0px;
}

.CustomisationModalM {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9999999999;
    opacity:1;
    -webkit-transition: opacity 100ms ease-in;
    -moz-transition: opacity 100ms ease-in;
    transition: opacity 100ms ease-in;
    pointer-events: auto;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    height: 100vh;
    width: 100vw;
}

.ddCustomisationModalLayoutM{
    position: relative; 
    width: 100%;
    height: 400px;
    overflow-y: auto;
    margin-bottom: 20px;
    padding: 30px 20px;
}

.ddCustomisationModalLayoutM::-webkit-scrollbar {
    width: 0px;
}

.ddNoItemsInCart{
    width: 100%;
    text-align: center;
}
.ddNoItemsInCartImage{
    margin-top: 30px;
    width: 70%;
    border-radius: 50%;
}

.RatingModalContainer {
    max-width: 600px;
    width: 80%;
    position: relative;
    margin: 5vh auto;
    border-radius: 25px;
    height: 90vh;
    cursor: auto;
    background-color: white;
    display: flex;
    flex-flow: column;
}

.AuthPopupModalContainer{
    max-width: 900px;
    margin: auto !important;
    height: unset !important;
}

.SuccessModalContainer{
    max-width: 500px;
    width: 80%;
    position: relative;
    margin: 30vh auto;
    border-radius: 10px;
    cursor: auto;
    background-color: white;
    display: flex;
    flex-flow: column;
}

.ddOrderDetailsModalContainer{
    max-width: 600px;
    width: 80%;
    position: relative;
    margin: 30vh auto;
    border-radius: 5px;
    cursor: auto;
    background-color: white;
    display: flex;
    flex-flow: column;
}

.CustomisationSuccessModalContainer{
    max-width: 600px;
    width: 80%;
    position: relative;
    margin: 30vh auto;
    border-radius: 10px;
    cursor: auto;
    background-color: white;
    display: flex;
    flex-flow: column;
}

.CustomisationSuccessModalContainerM{
    max-width: 600px;
    width: 90%;
    position: relative;
    margin: 30vh auto;
    border-radius: 10px;
    cursor: auto;
    background-color: white;
    display: flex;
    flex-flow: column;
}

.ddDishCustomisationFoodName{
    font-family: "Poppins-Semibold";
    color: #141414;
    font-size: 18px;
}

.ddDishCustomisationCustomTypeName{
    font-family: "Poppins-Medium";
    color: #141414;
    font-size: 18px;
}

.ddDishCustomisationCustomTypeSubHeading{
    font-family: "Poppins-Regular";
    font-size: 12px;
    color: #5F5F5F;
}

.ddDishCustomisationCustomItem{
    font-family: "Poppins-Regular";
    font-size: 15px;
    color: #141414;
    padding-top: 6px;
}

.ddDishCustomisationCustomPrice{
    font-family: "Poppins-Regular";
    color: #5F5F5F;
    font-size: 15px;
}

.ddAddCartBtn1{
    background: #FCD844;
    font-family: "Poppins-Medium";
    color: #2B2624;
    font-size: 14px;
    position: relative;
    display: inline-block;
    text-align: center;
    padding: 8px 15px 8px 15px;
    border-radius: 50px;
    cursor: pointer;
    transition: all 100ms ease-in-out;
    width: 100%;
}
.ddAddCartBtn1:hover{
    background: #EA5B2F;
    color: white;
    transition: all 100ms ease-in-out;
}

.ddAddCartBtn1M{
    background: #FCD844;
    font-family: "Poppins-Medium";
    color: #2B2624;
    font-size: 14px;
    position: absolute;
    display: inline-block;
    text-align: center;
    padding: 8px 30px 8px 30px;
    border-radius: 50px;
    cursor: pointer;
    transition: all 100ms ease-in-out;
    /* width: 205px; */
}
.ddAddCartBtn1M:hover{
    background: #EA5B2F;
    color: white;
    transition: all 100ms ease-in-out;
}
  
.ddAddCartBtnIcon1{
    font-size: 17px;
    position: relative;
    top: 2px;
}

.ddcart-Item-Controls{
    border: #E8E8E8 1px solid;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 3px 0px 3px 0px;
    height: 30px;
    top: 2px;
    position: relative;
}

.ddcart-Item-ControlsM{
    border: #E8E8E8 1px solid;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 8px 0px 8px 0px;
    height: 30px;
    top: 2px;
    position: relative;
}

.ddadd-quantity{
    color: black;
    border-radius: 50%;
    background: #FCD844;
    margin: 0px 5px;
    top: 5px;
    position: relative;
    cursor: pointer;
}

.ddremove-quantity{
    color: black;
    border-radius: 50%;
    background: #FCD844;
    margin: 0px 5px;
    cursor: pointer;
    top: 5px;
    position: relative;
}

.dditem-Qty{
    margin: 0px 5px;
    padding: 0px 10px;
    font-size: 0.75rem;
    font-family: "Poppins-Medium";
    display: inline-block;
    transform: translateY(-2px);
}

.ddHeaderForCart{
    font-family: "Quicksand-Medium";
    font-size: 30px;
}

.ddSubHeaderForCart{
    font-family:"Poppins-Regular";
    font-size: 15px;
}

.close {
    font-family: Arial, Helvetica, sans-serif;
    background: #ECECEC;
    color: #CEC3BC;
    line-height: 25px;
    position: absolute;
    right: -12px;
    text-align: center;
    top: -10px;
    width: 34px;
    height: 34px;
    text-decoration: none;
    font-weight: bold;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -moz-box-shadow: 1px 1px 3px #000;
    -webkit-box-shadow: 1px 1px 3px #000;
    box-shadow: 1px 1px 3px #000;
    padding-top: 5px;
    z-index: 400;
    cursor: pointer;
}
.close:hover {
    background: #707070;
    color: #ECECEC;
}

.DishImageModalContainer{
    height: 50%;
    overflow: hidden;
    width: 100%;
    z-index: 10;
    position: relative;
    border-radius: 25px 25px 0px 0px;
    background-color: white;
}

.ddDishImageModalContainer{
    width: 100%;
    z-index: 10;
    position: relative;
    border-radius: 25px 25px 0px 0px;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 25px 0px;
}

.RatingInformationTop{
    border-radius: 25px 25px 0px 0px;
    background-color: #F9D543;
    padding: 30px 0px;
}

.RatingInformationSubHeader{
    padding: 0px 10%;
    text-align: center;
    margin-top: 2vh;
    font-family: 'Quicksand-Regular';
    letter-spacing: -1px;
    font-size: 17px;
}

.RatingInformationHeader{
    padding: 0px 10%;
    text-align: center;
    margin-top: 4vh;
    font-family: 'Quicksand-Bold';
    letter-spacing: -1px;
    font-size: 2.6vw;
}

.RatingInformationTopEmojiContainer{
    width: 18vh;
    padding: 10px;
    border-radius: 100%;
    margin: auto;
    background-color: white;
    transform: translateY(8vh);
}

.RatingInformationTopEmoji{
    width: 100%;
}

.RatingInformationImagesContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.RatingInformationLeftImage{
    padding-right: 4%; 
    padding-left: 13%; 
    width: 33.33%;
}

.RatingInformationRightImage{
    padding-left: 4%; 
    padding-right: 13%; 
    width: 33.33%;
}

.RatingInformationCenterImage{
    padding: 0px 2%;
    width: 33.33%;
}

.RatingInformationImage{
    width: 100%;
}

.CenterImage{
    width: 12vw;
}

.RightImage, .LeftImage{
    width: 8vw;
    margin: auto;
}

.SuccessImageModalContainer{
    width: 100%;
    z-index: 1000000;
    /* position: relative; */
    border-radius: 9px 9px 0px 0px;
    background-color: white;
    overflow: visible;
    height: 135px;
}

.DishImageModal{
    max-width: 100%;
    margin: auto;
    transform: translateY(-20%);
}

.ddDishImageModal{
    width: 200px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 8px 8px 0px #DED090;
    border: 4px solid #FFFAE2;
    border-radius: 28px;
    opacity: 1;
}

.DishImageModalMobile{
    width: 200px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 8px 8px 0px #DED090;
    border: 4px solid #FFFAE2;
    border-radius: 28px;
    opacity: 1;
}

.SuccessImageModal{
    display: absolute;
    /* background-color: white; */
    /* padding: 10px 0px 10px 10px; */
    /* border-radius: 50%; */
    width: 35%;
    margin-left: 33%;
    bottom:-100%;
    overflow: visible;
    transform: translate(0%, -30%);
}

.DishImageModalBlur{
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    overflow: hidden;
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    transition: 0.5s ease;
    z-index: 4000;
    /* background-image: linear-gradient(
      rgba(0, 0, 0, 0) ,
      rgba(0, 0, 0, 0.8)
    ); */
}

.DishImageModalText{
    position: absolute;
    z-index: 5000;
    margin: 0 auto;
    opacity: 1.2;
    bottom: 20px;
    max-width: 75%;
    left: 0%; /* Adjust this value to move the positioned div up and down */
    color: #FFF;
    display: inline-block;
}

.RedDotModal{
    display: inline-block;
    position: absolute;
    bottom: 25px;
    left: 25px;
    height: 6px;
    width: 6px;
    background-color: red;
    margin-right: 10px;
    border: 2px solid white;
    outline: 2px solid red;
}

.vegTag{
    position: relative;
    color: green;
    border: 2px solid green;
    right: 5px;
  }
  
  .nonVegTag{
    position: relative;
    color: red;
    border: 2px solid red;
    right: 5px;
  }

.DishNameModal{
    font-family: 'ProximaNova-Bold';
    font-size: 16px;
    margin-left: 40px;
}

.DishRestaurantModal{
    color: #F9D543;
    font-family: 'ProximaNova-Medium';
    font-size: 15px;
    margin-left: 40px;
}

.DishRateModalBelowContainer{
    height: 60%;
    z-index: 1000;
    background-color: white;
    border-radius: 25px;
    display: flex;
    flex-flow: column;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    align-items: center;
}

.RestaurantContactImageContainer{
    margin: auto;
    margin-top: 40px;
    width: 100px;
    height: 100px;
    background-color: white;
    padding: 25px;
    border-radius: 50%;
}

.RestaurantContactImage{
    width: 50px;
    height: 50px;
}

.RestaurantContactModalBelowContainer{
    overflow-y: scroll;
    max-height: 250px;
    margin: 20px auto;
}

.RestaurantContactModalBelowContainer::-webkit-scrollbar {
    width: 0px;
}

.DishRateModalBelowContainer::-webkit-scrollbar {
    width: 0px;
}

.RatingInformationContainer{
    z-index: 100;
    background-color: white;
    border-radius: 25px;
    display: flex;
    flex-flow: column;
}

.AuthPopupContainer{
    flex-flow: row;
}

.AuthPopupLeft{
    width: 36%;
    display: flex;
    flex-direction: column;
    background-color: #FFF7A6;
    border-radius: 20px 0px 0px 25px;
}

.AuthPopupRight{
    width: 64%;
    display: flex;
    flex-direction: column;
    border-radius: 0px 25px 25px 0px;
    background-color: white;
    overflow-y: scroll;
}

.AuthPopupRightLogoContainer{
    margin: auto;
    width: 120px;
}

.AuthPopupRightLogo{
    width: 120px;
}

.AuthPopupLeftTitle{
    padding: 20px 40px;
    margin-top: 60px;
    font-family: 'Quicksand-SemiBold';
    letter-spacing: -1px;
    font-size: 1.7vw;
}

.AuthPopupLeftSubTitle{
    padding: 5px 40px;
    font-family: 'Poppins-Regular';
    font-size: 1.0vw;
    line-height: 25px;
    flex: 1;
}

.AuthPopupLeftImageContainer{
    padding: 30px 0px;
    margin: auto;
    margin-bottom: 20px;
}

.AuthPopupLeftImage{
    height: 30vh;
}

.DishRateModalHeader{
    width: 100%;
    text-align: center;
    padding: 4% 0px;
    font-family: 'Montserrat-SemiBold';
}

.DishRateModalStarsContainer{
    padding: 5px 3%;
    margin: 5% auto 0px auto;
    background-color: #FCFCFC;
    box-shadow: 0px 3px 40px #00000029;
    border-radius: 60px;
    position: absolute;
    width: 90%;
    height: 80px;
    text-align: center;
}

.DishRateModalStarsContainerMobile{
    padding: 5px 3%;
    margin: 5% auto 0px auto;
    background-color: #FCFCFC;
    box-shadow: 0px 3px 40px #00000029;
    border-radius: 60px;
    position: absolute;
    width: 90%;
    height: 60px;
    text-align: center;
}

.DishRateModalStars{
    display: inline-block;
    cursor: pointer;
}

.DishRateModalStarsImage{
    width: 35px;
    margin: 0px 5px;
}

.DishRateModalArrowDown {
    width: 0; 
    height: 0; 
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    margin: 0px auto;
    border-top: 20px solid #FCFCFC;
}

.DishRateModalTitle{
    margin: 0px auto;
    background-color: #F5F5F5;
    padding: 8px 5%;
    border-radius: 12px;
    font-family: 'ProximaNova-SemiBold';
    font-size: 18px;
}

.RatePopupStar{
    font-size: 18px !important;
    color: #F9D543 !important;
    position: relative;
    margin-left: 5px;
    top: 3px;
  }

.DishRateModalAlternateImageContainer{
    width: 120px;
    margin: auto;
    max-height: 15vh;
    flex: 1;
}

.DishRateModalSuccessImageContainer{
    margin: auto;
    height: 60%;
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-flow: column;
}

.DishRateModalAlternateImage{
    width: 100%;
}

.DishRateModalSuccessImage{
    height: 100%;
    margin: auto;
}

.DishRateModalSuccessText{
    margin: auto;
    font-family: 'Montserrat-Regular';
    font-size: 15px;
}

.DishRateModalFormContainer{
    display: flex;
    flex: 1;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    flex-flow: column;
    padding-top: 95px;
}

.DishRateModalWriteReviewContainer{
    flex: 1;
    width: 100%;
    margin-top: 4%;
    margin-bottom: 4%;
    background-color: #F5F5F5;
    border-radius: 5px;
    color: #525252;
    font-family: 'ProximaNova-Regular';
    padding: 2.5%;
    font-size: 14px;
    position: relative;
}

.DishRateModalTextArea{
    width: 100%; 
    height: 100%; 
    background-color: transparent;
    outline: none;
    border: 0px;
    resize: none;
    font-family: 'Poppins-Regular';
}

.WriteReviewCharacters{
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: #aaa;
    font-size: 12px;
}

.DishRateModalSubmitButtonContainer{
    display: inline-block;
    padding: 15px 60px;
    border-radius: 40px;
    margin: auto;
    background-color: #EA5B2F;
    color: white;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 30px;
}

.DishReviewModalHeader{
    width: 90%;
    margin-left: 5%;
    text-align: center;
    padding: 4% 0px;
    font-family: 'Montserrat-SemiBold';
    border-bottom: 0.5px solid #ECECEC;
}

.ResendOTPText{
    font-size: 12px;
    text-align: right;
    padding-bottom: 5px;
    cursor: pointer;
}

.ResendOTPSuccessMessage{
    text-align: center;
    font-size: 13px;
}

.DishSuccessModalHeader{
    width: 90%;
    margin: 0px 5%;
    text-align: center;
    padding: 10px 0px;
    font-family: 'Quicksand-Regular';
    letter-spacing: -1px;
    flex: 1;
    font-size: 28px;
    z-index: 100000;
    letter-spacing: 0.0005rem;
}

.DishSuccessModalSubHeader{
    text-align: center;
    margin: 10px 0px;
    font-size: 13px;
    font-family: 'Poppins-Medium';
}

.RestaurantContactNumber, .RestaurantContactNumberActive{
    font-family: 'Poppins-Regular';
    color:#828282;
    margin: 5px 20px;
    border-radius: 5px;
    padding: 10px 42px;
    font-size: 18px;
    background-color: #F8F8F8;
    cursor: pointer;
}

.RestaurantContactNumberActive{
    background-color: #FFDFD5;
    color: #EA5B2F;
}

.DishReviewList{
    flex: 1;
    overflow-y: auto;
    margin: 4% 5%;
    margin-top: 10px;
}

.DishReviewCard{
    display: flex;
    padding-top: 15px;
}

.DishReviewDetails{
    flex: 1;
    margin-left: 5px;
    padding-right: 15px;
}

.DishReviewUserImageContainer{
    width: 30px;
    height: 30px; 
    margin-right: 10px;
    margin-left: 5px;
}

.DishReviewUserImage{
    width: 100%;
    height: 100%;
}

.DishReviewUser{
    font-family: 'Montserrat-SemiBold';
    font-size: 14px;
}

.DishReviewRating{
    padding: 2px 10px;
    background-color: #1EB10E;
    display: inline-block;
    border-radius: 10px;
    font-size: 12px;
    color: white;
    font-family: 'ProximaNova-SemiBold';
    margin-top: 5px;
}

.DishReviewRatingImage{
    margin-left: 5px;
    width: 10px;
}

.DishReviewDescription{
    font-family: 'Raleway-Medium';
    color: #515151;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: justify;
    padding-bottom: 15px;
    border-bottom: 0.5px solid #ECECEC;
}

.CuisinesHeader{
    padding: 2vw;
    padding-bottom: 0px;
    font-family: 'Poppins-Medium';
    font-size: 18px;
}

#mail-input{
    font-family: 'Poppins-Regular';
    color: rgba(255, 255, 255, 1);
    padding: 15px 25px;
}

#mail-input::placeholder{
    font-family: 'Raleway-Regular';
    color: rgba(255, 255, 255, 0.8);
    transform: translateX(-5px);
}

.CuisinesList{
    flex: 1;
    overflow-y: scroll;
    overflow-x: hidden;
}

.CuisinesList::-webkit-scrollbar{
    width: 0px;
}

.DishReviewList::-webkit-scrollbar{
    width: 0px;
}

.AuthPopupRight::-webkit-scrollbar{
    width: 0px;
}

.DishRateModalBelowContainer::-webkit-scrollbar{
    width: 0px;
}

#main-content::-webkit-scrollbar{
    width: 0px;
}

#root::-webkit-scrollbar{
    width: 0px;
}

.CuisinesColumn{
    width: 33%;
    padding: 4px 3vw;
    display: inline-block;
}

.CuisinesItem{
    padding-bottom: 5px;
    padding-top: 5px;
    font-family: 'Poppins-Regular';
    font-size: 14px;
    cursor: pointer;
}

.CuisinesButtons{
    padding: 3vh 1vw;
    display: flex;
}

.CuisinesApply{
    flex: 1;
    background-color: #FF052E;
    margin: 0px 12px;
    text-align: center;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 8px;
    color: white;
    font-family: 'Poppins-Medium';
    font-size: 16px;
    cursor: pointer;
}

.CuisinesClear{
    flex: 1;
    background-color: #E1E1E1;
    margin-left: 2%;
    margin-right: 2%;
    text-align: center;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 8px;
    font-family: 'Poppins-Medium';
    font-size: 16px;
    cursor: pointer;
}

.circle, .contactCircle {
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
/* --------------------------------- Circles --------------------------------- */
.circle, .contactCircle {
    width: 35%;
    height: 35%;
    border-radius: 50%;
    background-color: #f4c3d1;
    position: absolute;
    opacity: 0;
    left: 50%;
    top: 10.5%;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
}
.circle:nth-child(3n+0) {
    -webkit-animation: undulate 3s infinite;
    -moz-animation: undulate 3s infinite;
    -o-animation: undulate 3s infinite;
    animation: undulate 3s infinite;
    -webkit-animation-delay: 0ms;
    animation-delay: 0ms;
}
.circle:nth-child(3n+1) {
    -webkit-animation: undulate 3s infinite;
    -moz-animation: undulate 3s infinite;
    -o-animation: undulate 3s infinite;
    animation: undulate 3s infinite;
    -webkit-animation-delay: 300ms;
    animation-delay: 300ms;
}
.circle:nth-child(3n+2) {
    -webkit-animation: undulate 3s infinite;
    -moz-animation: undulate 3s infinite;
    -o-animation: undulate 3s infinite;
    animation: undulate 3s infinite;
    -webkit-animation-delay: 600ms;
    animation-delay: 600ms;
}
.circle:nth-child(3n+3) {
    -webkit-animation: undulate 3s infinite;
    -moz-animation: undulate 3s infinite;
    -o-animation: undulate 3s infinite;
    animation: undulate 3s infinite;
    -webkit-animation-delay: 900ms;
    animation-delay: 900ms;
}

.topText1{
    text-align: center;
    position: relative;
    padding-top: 10px;
    font-size: 40px;
    font-family: 'Quicksand-Bold';
    letter-spacing: -1px;
}

.ContentDescriptionText1{
    color: #000000;
    font-family: 'Quicksand-SemiBold';
    letter-spacing: -1px;
    opacity: 1;
    font-size: 25px;
    text-align: center;
  }

.pageNotFoundImage{
    z-index: 1000;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    background: #FFFFFF;
}

.ddHeaderCartIcon{
    cursor: pointer;
    padding: 5px 12px 5px 12px;
    border-radius: 6px;
    transition: all 100ms ease-in-out;
    color: white;
    font-family: 'Poppins-Medium';
}

.ddCartHeaderBtnsCoating{
    background: #EFEFEF;
    padding: 8px 0px 13px 2px;
    border-radius: 50px;
}
.ddCartHeaderBtns{
    background: #FFFFFF;
    color: #2B2624;
    display: inline-block;
    width: 150px;
    border-radius: 50px;
    padding: 9px 0px 9px 0px;
    margin-right: 2px;
    cursor: pointer;
    font-family: "Poppins-Medium";
    font-size: 13px;
}
.ddCartHeaderBtns-selected{
    background: #2B2624;
    color: #FFFFFF;
    display: inline-block;
    width: 150px;
    border-radius: 50px;
    padding: 9px 0px 9px 0px;
    margin-right: 2px;
    cursor: pointer;
    font-family: "Poppins-Medium";
    font-size: 13px;
}

.ddCartItemsBlock{
    display: inline-block;
    border: #E1E1E1 1px solid;
    width: 480px;
    padding-bottom: 10px;
    margin-top: 5px;
    margin-bottom: 30px;
    border-radius: 5px;
    box-shadow: 2px 2px 2px #aaaaaa;
}

.ddCartItemsBlockM{
    display: inline-block;
    border: #E1E1E1 1px solid;
    padding-bottom: 10px;
    margin-top: 5px;
    margin-bottom: 30px;
    border-radius: 5px;
    box-shadow: 2px 2px 2px #aaaaaa;
}

.ddCartItemsBlock2{
    display: inline-block;
    height: 370px;
    margin-right: 30px;
    margin-left: 30px;
    width: 90%;
    overflow-y: auto;
}

.ddCartItemsBlock2::-webkit-scrollbar {
    width: 5px;
}
.ddCartItemsBlock2::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #c7c7c7; 
    border-radius: 10px;
}
.ddCartItemsBlock2::-webkit-scrollbar-thumb {
    background: #c7c7c7; 
    border-radius: 10px;
}
.ddCartItemsBlock2::-webkit-scrollbar-thumb:hover {
    background: #929292; 
}

.ddCartSubTotalDiv{
    background: #FFFFFF;
    bottom: 0px;
    cursor: pointer;
    color: #2B2624;
    font-family: "Poppins-Medium";
    font-size: 13px;
    display: inline-block;
    width: 100%;
    height: 70px;
    padding: 20px 30px 0px 30px;
    box-shadow: 2px -3px 5px 0px #aaaaaa;
}


.ddCartModeOfDeliveryBlocks{
    background: #F9F9F9;
    margin-left: 20px;
    margin-right: 20px;
    padding: 15px;
    font-family: "Poppins-Medium";
    font-size: 15px;
}

.ddCartBtns{
    background: #FCD844;
    cursor: pointer;
    color: #2B2624;
    font-family: "Poppins-Medium";
    font-size: 13px;
    display: inline-block;
    width: 100px;
    height: 30px;
    margin-bottom: 5px;
    border-radius: 5px;
    text-align: center;
    padding-top: 6px;
    margin-top: -3px;
    transition: all 100ms ease-in-out;
}
.ddCartBtns:hover{
    font-size: 13.5px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.2);
    transition: all 100ms ease-in-out;
}

.ddCartBtnsFlat{
    background: #FFFFFF;
    cursor: pointer;
    color: #2B2624;
    border: #2B2624 1px solid;
    font-family: "Poppins-Medium";
    font-size: 13px;
    display: inline-block;
    width: 100px;
    height: 30px;
    margin-bottom: 5px;
    border-radius: 5px;
    text-align: center;
    padding-top: 4px;
    margin-top: -3px;
    transition: all 100ms ease-in-out;
}
.ddCartBtnsFlat:hover{
    font-size: 13.5px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.2);
    transition: all 100ms ease-in-out;
}

.ddCartBackBtns{
    cursor: pointer;
    color: #2B2624;
    font-family: "Poppins-Medium";
    font-size: 13px;
    transition: all 100ms ease-in-out;
}
.ddCartBackBtns:hover{
    color: #1458a7;
    transition: all 100ms ease-in-out;
}

.ddCartCancelBtns{
    background: #FFFFFF;
    font-family: "Poppins-Medium";
    color: #2B2624;
    font-size: 14px;
    position: relative;
    display: inline-block;
    text-align: center;
    padding: 8px 30px 8px 30px;
    border : #EA5B2F 1px solid;
    border-radius: 50px;
    cursor: pointer;
    transition: all 100ms ease-in-out;
}

.ddUpiBlock{
    border: #E0E0E0 1px solid;
    margin: 0px 20px;
    padding: 10px 15px 10px 15px;
}

.ddPayPartnerIcons{
    font-size: 35px;
    border: #aaaaaa 1px solid;
    border-radius: 50%;
    padding: 2px;
    margin-right: 5px;
}

.ddUPIText{
    padding: 10px;
    width: 100%;
    background: #FDFDFD;
    font-size: 14px;
    outline: none;
    border: #EDEDED 1px solid;
    font-family: "Poppins-Regular";
}
.ddUPIText::placeholder{
    opacity: 0.4;
    font-family: "Poppins-Regular";
}

.ddInfoIcon{
    font-size: 15px;
    position: relative;
    top: 3px;
    left: 2px;
    cursor: pointer;
    margin-right: 4px;
}

  .tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: #FFFFFF;
    color: #5E4B45;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    top: -19px;
    left: 160%;
    border: #5E4B45 1px dashed;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #5E4B45 transparent transparent;

  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .optionTooltip {
    position: relative;
    display: inline-block;
  }
  
  .optionTooltip .optionTooltiptext {
    visibility: hidden;
    width: 100px;
    background-color: rgb(226, 226, 226);
    color: #000000;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    font-family: "Poppins-Medium";
    /* Position the tooltip */
    position: absolute;
    z-index: 1000;
    top: 100%;
    left: 50%;
    margin-left: -60px;
    margin-top: -45px;
  }
  
  .optionTooltip:hover .optionTooltiptext {
    visibility: visible;
  }

  .ddReviewNote{
      background-color: #FFFFFF;
      border: #D3D3D3 1px solid;
      /* margin-left: -4px; */
      padding: 15px;
      width: 100%;
      display: inline-block;
  }
  
  .ddReviewNoteHeader{
      font-family: "Poppins-Medium";
      font-size: 13px;
      color: #5E4B45;
      margin-bottom: 10px;
  }
  
  .ddReviewNoteText{
    font-family: "Poppins-Regular";
    font-size: 10px;
    color: #5E4B45;
  }

  .ddSmallLinks{
      font-family: "Poppins-Medium";
      color: #1E429A;
      position: relative;
      top: 5px;
      margin-right: 20px;
      font-size: 10px;
      border-bottom: #1E429A 1px dotted;
      cursor: pointer;
  }

  .ddOrderStatusImages{
      width: 100%;
  }

  .ddOrderStatusBanner{
      background: #FAFAFA;
      padding: 20px 20px 22px 20px;
      color: #55B7AD;
      font-family: "Quicksand-Bold";
      font-size: 20px;
  }
  .ddOrderStatusDetails{
    background: #FAFAFA;
    /* padding: 20px 20px 22px 20px; */
    padding-bottom: 10px;
    margin-top: -20px;
    color: #55B7AD;
    font-family: "Quicksand-Bold";
    font-size: 15px;
}

.ddSelectedIcon{
    background: #5E4B45;
    color: white;
    padding: 8px 9px 5px 9px;
    border-radius: 50%;
    border: #5E4B45 1px solid;
}

.ddNotSelectedIcon{
    background: white;
    color: #5E4B45;
    padding: 8px 9px 5px 9px;
    border-radius: 50%;
    border: #F2E8E6 1px solid;
}

.ddCartSectionText{
    color: #5E4B45;
    font-family: "Poppins-Medium";
    font-size: 13px;
}

.ddSelectedDeliveryMode{
    font-family: "Quicksand-Semibold";
    color: #EA5B2F;
    margin-right: 10px;
    padding: 0px 8px 5px 8px;
    border-bottom: #EA5B2F 2px solid;
    cursor: pointer;
    transition: all 100ms ease-in-out;
}

.ddDeliveryMode{
    font-family: "Quicksand-Semibold";
    color: #2B2624;
    margin-right: 10px;
    padding: 0px 8px 5px 8px;
    cursor: pointer;
    border-bottom: #FFFFFF 2px solid;
    transition: all 100ms ease-in-out;
}

.ddPopOverCartBtn1{
    background: #FCD844;
    font-family: "Poppins-Medium";
    color: #2B2624;
    font-size: 12px;
    position: relative;
    display: inline-block;
    text-align: center;
    padding: 5px 0px;
    width: 180px;
    border-radius: 50px;
    cursor: pointer;
    transition: all 100ms ease-in-out;
  }
  .ddPopOverCartBtn1:hover{
    background: #EA5B2F;
    color: white;
    transition: all 100ms ease-in-out;
  }

  .ddPopOverCartBtn1M{
    background: #EA5B2F;
    font-family: "Poppins-Medium";
    color: #2B2624;
    font-size: 12px;
    position: relative;
    display: inline-block;
    text-align: center;
    padding: 5px 0px;
    width: 180px;
    border-radius: 50px;
    cursor: pointer;
    transition: all 100ms ease-in-out;
    box-shadow: 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 3px #EA5B2F;
  }

  .ddPopOverCartBtnFlatM{
    background: #FFFFFF;
    font-family: "Poppins-Medium";
    color: #d3d2d2;
    font-size: 12px;
    position: relative;
    display: inline-block;
    text-align: center;
    padding: 5px 0px;
    width: 180px;
    border-radius: 50px;
    cursor: pointer;
    transition: all 100ms ease-in-out;
    border: #EA5B2F 1px solid;
    box-shadow: 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 3px #EA5B2F;
  }

  .ddPopOverCartBtnFlat{
    background: #FFFFFF;
    font-family: "Poppins-Medium";
    color: #2B2624;
    font-size: 12px;
    position: relative;
    display: inline-block;
    text-align: center;
    padding: 5px 0px;
    width: 180px;
    border-radius: 50px;
    border: #2B2624 1px solid;
    cursor: pointer;
    transition: all 100ms ease-in-out;
  }

  .ddCareersHeader{
    display: inline-flex;
    width: 100%;
    margin-top: 5vh;
    color: #2B2624;
    align-items: center;
    flex-direction: column;
  }

  .ddCareerVectorImages{
    width: 100px;
  }

  .ddCareerBoxes{
    border: #E3E3E3 1px solid;
    background: #FFFFFF;
    border-radius: 5px;
    height: 200px;
    padding: 20px;
    position: relative;
  }

  .ddCareersTag{
    padding: 0px 10px;
    border-radius: 2px;
    font-size: 10px;
    font-family: "Poppins-SemiBold";
  }

  .ddCarrerApplyNowBtn{
    background: #FCD844;
    font-family: "Poppins-Medium";
    color: #2B2624;
    font-size: 12px;
    position: absolute;
    bottom: 10px;
    display: inline-block;
    text-align: center;
    padding: 5px 20px;
    border-radius: 50px;
    cursor: pointer;
    transition: all 100ms ease-in-out;
  }
  
  .ddCarrerApplyNowBtn:hover{
    background: #EA5B2F;
    color: white;
    transition: all 100ms ease-in-out;
  }

  .ddCarrerSubmitBackBtn{
    background: #FCD844;
    font-family: "Poppins-Medium";
    color: #2B2624;
    font-size: 12px;
    display: inline-block;
    text-align: center;
    padding: 5px 20px;
    border-radius: 50px;
    cursor: pointer;
    transition: all 100ms ease-in-out;
    margin: 0px 10px;
    width: 150px;
  }
  
  .ddCarrerSubmitBackBtn:hover{
    background: #EA5B2F;
    color: white;
    transition: all 100ms ease-in-out;
  }

  .ddTextFieldStyle::placeholder{
    font-size: 10px;
  }


    .ddNoScrollBar::-webkit-scrollbar {
        width: 5px;
    }
    .ddNoScrollBar::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #c7c7c7; 
        border-radius: 10px;
    }
    .ddNoScrollBar::-webkit-scrollbar-thumb {
        background: #c7c7c7; 
        border-radius: 10px;
    }
    .ddNoScrollBar::-webkit-scrollbar-thumb:hover {
        background: #929292; 
    }


/* --------------------------------- Animation --------------------------------- */
@-webkit-keyframes undulate {
    0% {
        width: 50%;
        height: 50%;
        opacity: 1;
   }
   14% {
        background-color: rgba(221,230,223, 0.78);
    }
    21% {
        background-color: rgba(221,230,223, 0.8);
    }
    28% {
        background-color: rgba(221,230,223, 0.82);
    }
    35% {
        background-color: rgba(221,230,223, 0.84);
    }
    42% {
        background-color: rgba(221,230,223, 0.86);
    }
    49% {
        background-color: rgba(221,230,223, 0.88);
    }
    56% {
        background-color: rgba(221,230,223, 0.9);
    }
    63% {
        background-color: rgba(221,230,223, 0.92);
    }
    70% {
        background-color: rgba(221,230,223, 0.94);
    }
    77% {
        background-color: rgba(221,230,223, 0.96);
    }
    84% {
        background-color: rgba(221,230,223, 0.98);
    }
    91% {
        background-color: rgba(221,230,223, 1);
    }
    100% {
        background-color: #150525;
        width: 17vw;
        height: 17vw;
        opacity: 0;
   }
}
@-moz-keyframes undulate {
    0% {
        width: 50%;
        height: 50%;
        opacity: 1;
   }
   14% {
        background-color: rgba(221,230,223, 0.78);
    }
    21% {
        background-color: rgba(221,230,223, 0.8);
    }
    28% {
        background-color: rgba(221,230,223, 0.82);
    }
    35% {
        background-color: rgba(221,230,223, 0.84);
    }
    42% {
        background-color: rgba(221,230,223, 0.86);
    }
    49% {
        background-color: rgba(221,230,223, 0.88);
    }
    56% {
        background-color: rgba(221,230,223, 0.9);
    }
    63% {
        background-color: rgba(221,230,223, 0.92);
    }
    70% {
        background-color: rgba(221,230,223, 0.94);
    }
    77% {
        background-color: rgba(221,230,223, 0.96);
    }
    84% {
        background-color: rgba(221,230,223, 0.98);
    }
    91% {
        background-color: rgba(221,230,223, 1);
    }
    100% {
        background-color: #150525;
        width: 17vw;
        height: 17vw;
        opacity: 0;
   }
}
@-ms-keyframes undulate {
    0% {
        width: 50%;
        height: 50%;
        opacity: 1;
   }
   14% {
        background-color: rgba(221,230,223, 0.78);
    }
    21% {
        background-color: rgba(221,230,223, 0.8);
    }
    28% {
        background-color: rgba(221,230,223, 0.82);
    }
    35% {
        background-color: rgba(221,230,223, 0.84);
    }
    42% {
        background-color: rgba(221,230,223, 0.86);
    }
    49% {
        background-color: rgba(221,230,223, 0.88);
    }
    56% {
        background-color: rgba(221,230,223, 0.9);
    }
    63% {
        background-color: rgba(221,230,223, 0.92);
    }
    70% {
        background-color: rgba(221,230,223, 0.94);
    }
    77% {
        background-color: rgba(221,230,223, 0.96);
    }
    84% {
        background-color: rgba(221,230,223, 0.98);
    }
    91% {
        background-color: rgba(221,230,223, 1);
    }
    100% {
        background-color: #150525;
        width: 17vw;
        height: 17vw;
        opacity: 0;
   }
}
@keyframes undulate {
    0% {
        width: 10vw;
        height: 10vw;
        opacity: 1;
   }
   14% {
        background-color: rgba(221,230,223, 0.78);
    }
    28% {
        background-color: rgba(221,230,223, 0.82);
    }
    42% {
        background-color: rgba(221,230,223, 0.86);
    }
    56% {
        background-color: rgba(221,230,223, 0.9);
    }
    70% {
        background-color: rgba(221,230,223, 0.94);
    }
    84% {
        background-color: rgba(221,230,223, 0.98);
    }
    100% {
        background-color: #150525;
        width: 17vw;
        height: 17vw;
        opacity: 0;
   }
}

@keyframes confettiRain {
    0% {
      opacity: 1;
      margin-top: -100vh;
      margin-left: -200px;
    } 
    
    100% {
      opacity: 1;
      margin-top: 100vh;
      margin-left: 200px;
    }
  }
  
.confetti {
    opacity: 0;
    position: absolute;
    width: 1rem;
    height: 1.5rem;
    animation: confettiRain 8s infinite;
}

.CallButtonContainer{
    margin: auto !important;
    width: 10vw !important;
    height: 10vw !important;
    padding: 1.5vw !important;
    border-radius: 10vw !important;
    background-color: rgba(211,211,211) !important;
    color: #24A335 !important;
    transform: translate(125%, -40px) !important;
}

.contactCircle:nth-child(3n+0) {
    -webkit-animation: contactUndulate 3s infinite;
    -moz-animation: contactUndulate 3s infinite;
    -o-animation: contactUndulate 3s infinite;
    animation: contactUndulate 3s infinite;
    -webkit-animation-delay: 0ms;
    animation-delay: 0ms;
}
.contactCircle:nth-child(3n+1) {
    -webkit-animation: contactUndulate 3s infinite;
    -moz-animation: contactUndulate 3s infinite;
    -o-animation: contactUndulate 3s infinite;
    animation: contactUndulate 3s infinite;
    -webkit-animation-delay: 300ms;
    animation-delay: 300ms;
}
.contactCircle:nth-child(3n+2) {
    -webkit-animation: contactUndulate 3s infinite;
    -moz-animation: contactUndulate 3s infinite;
    -o-animation: contactUndulate 3s infinite;
    animation: contactUndulate 3s infinite;
    -webkit-animation-delay: 600ms;
    animation-delay: 600ms;
}
.contactCircle:nth-child(3n+3) {
    -webkit-animation: contactUndulate 3s infinite;
    -moz-animation: contactUndulate 3s infinite;
    -o-animation: contactUndulate 3s infinite;
    animation: contactUndulate 3s infinite;
    -webkit-animation-delay: 900ms;
    animation-delay: 900ms;
}
/* --------------------------------- Animation --------------------------------- */
@-webkit-keyframes contactUndulate {
    0% {
        width: 50%;
        height: 50%;
        opacity: 1;
   }
   14% {
        background-color: rgba(211,211, 211, 0.78);
    }
    21% {
        background-color: rgba(211,211, 211, 0.8);
    }
    28% {
        background-color: rgba(211,211, 211, 0.82);
    }
    35% {
        background-color: rgba(211,211, 211, 0.84);
    }
    42% {
        background-color: rgba(211,211, 211, 0.86);
    }
    49% {
        background-color: rgba(211,211, 211, 0.88);
    }
    56% {
        background-color: rgba(211,211, 211, 0.9);
    }
    63% {
        background-color: rgba(211,211, 211, 0.92);
    }
    70% {
        background-color: rgba(211,211, 211, 0.94);
    }
    77% {
        background-color: rgba(211,211, 211, 0.96);
    }
    84% {
        background-color: rgba(211,211, 211, 0.98);
    }
    91% {
        background-color: rgba(211,211, 211, 1);
    }
    100% {
        background-color: #150525;
        width: 17vw;
        height: 17vw;
        opacity: 0;
   }
}
@-moz-keyframes contactUndulate {
    0% {
        width: 50%;
        height: 50%;
        opacity: 1;
   }
   14% {
        background-color: rgba(211,211, 211, 0.78);
    }
    21% {
        background-color: rgba(211,211, 211, 0.8);
    }
    28% {
        background-color: rgba(211,211, 211, 0.82);
    }
    35% {
        background-color: rgba(211,211, 211, 0.84);
    }
    42% {
        background-color: rgba(211,211, 211, 0.86);
    }
    49% {
        background-color: rgba(211,211, 211, 0.88);
    }
    56% {
        background-color: rgba(211,211, 211, 0.9);
    }
    63% {
        background-color: rgba(211,211, 211, 0.92);
    }
    70% {
        background-color: rgba(211,211, 211, 0.94);
    }
    77% {
        background-color: rgba(211,211, 211, 0.96);
    }
    84% {
        background-color: rgba(211,211, 211, 0.98);
    }
    91% {
        background-color: rgba(211,211, 211, 1);
    }
    100% {
        background-color: #150525;
        width: 17vw;
        height: 17vw;
        opacity: 0;
   }
}
@-ms-keyframes contactUndulate {
    0% {
        width: 50%;
        height: 50%;
        opacity: 1;
   }
   14% {
        background-color: rgba(211,211, 211, 0.78);
    }
    21% {
        background-color: rgba(211,211, 211, 0.8);
    }
    28% {
        background-color: rgba(211,211, 211, 0.82);
    }
    35% {
        background-color: rgba(211,211, 211, 0.84);
    }
    42% {
        background-color: rgba(211,211, 211, 0.86);
    }
    49% {
        background-color: rgba(211,211, 211, 0.88);
    }
    56% {
        background-color: rgba(211,211, 211, 0.9);
    }
    63% {
        background-color: rgba(211,211, 211, 0.92);
    }
    70% {
        background-color: rgba(211,211, 211, 0.94);
    }
    77% {
        background-color: rgba(211,211, 211, 0.96);
    }
    84% {
        background-color: rgba(211,211, 211, 0.98);
    }
    91% {
        background-color: rgba(211,211, 211, 1);
    }
    100% {
        background-color: #150525;
        width: 17vw;
        height: 17vw;
        opacity: 0;
   }
}
@keyframes contactUndulate {
    0% {
        width: 10vw;
        height: 10vw;
        opacity: 1;
   }
   14% {
        background-color: rgba(211,211, 211, 0.78);
    }
    21% {
        background-color: rgba(211,211, 211, 0.8);
    }
    28% {
        background-color: rgba(211,211, 211, 0.82);
    }
    35% {
        background-color: rgba(211,211, 211, 0.84);
    }
    42% {
        background-color: rgba(211,211, 211, 0.86);
    }
    49% {
        background-color: rgba(211,211, 211, 0.88);
    }
    56% {
        background-color: rgba(211,211, 211, 0.9);
    }
    63% {
        background-color: rgba(211,211, 211, 0.92);
    }
    70% {
        background-color: rgba(211,211, 211, 0.94);
    }
    77% {
        background-color: rgba(211,211, 211, 0.96);
    }
    84% {
        background-color: rgba(211,211, 211, 0.98);
    }
    91% {
        background-color: rgba(211,211, 211, 1);
    }
    100% {
        background-color: #150525;
        width: 17vw;
        height: 17vw;
        opacity: 0;
   }
}

@media (max-width: 500px) {
    .FooterColumnHeader{
        text-align: center;
    }

    .FooterColumnItem{
        text-align: center;
    }

    .Mail{
        width: 88vw;
        margin-left: 2vw;
    }

    #mail-input{
        font-size: 12px;
    }

    #mail-input::placeholder{
        font-size: 12px;
    }

    #mail-submit{
        margin: auto;
    }
    
    .mail-button-mobile{
        padding: 5px 35px !important;
        font-size: 12px;
    }

    .RatingInformationTop{
        border-radius: 15px 15px 0px 0px;
    }
    
    .RatingInformationSubHeader{
        margin-top: 20px;
        font-size: 13px;
    }
    
    .RatingInformationHeader{
        margin-top: 30px;
        font-size: 24px;
    }

    .RatingInformationImagesContainer{
        margin-top: 0px;
    }

    .RatingInformationLeftImage{
        padding-right: 2%; 
        padding-left: 6%; 
    }
    
    .RatingInformationRightImage{
        padding-left: 2%; 
        padding-right: 6%; 
    }
    
    .RatingInformationCenterImage{
        padding: 0px 1%;
    }

    .RatingInformationModal{
        max-width: 600px;
        width: 80%;
        position: relative;
        margin: 5vh auto;
        border-radius: 25px;
        height: 90vh;
        cursor: auto;
        background-color: white;
        display: flex;
        flex-flow: column;
    }

    .DishImageModalContainer{
        border-radius: 15px 15px 0px 0px;
    }

    .DishRateModalBelowContainer{
        height: 70%;
        border-radius: 15px;
    }

    .RatingModalContainer{
        width: 90%;
        border-radius: 15px;
    }

    .CuisinesItem{
        text-align: center;
    }

    .CuisinesHeader{
        margin-left: 10px;
        margin-top: 10px;
    }

    .DishReviewUserImage{
        width: 30px;
    }
    
    .DishReviewDescription{
        word-wrap: break-word;
    }

    .DishReviewList{
        word-break:break-all;
    }

    .AuthPopupContainer{
        flex-flow: column;
    }

    .AuthPopupLeftImageContainer{
        display: none;
    }

    .AuthPopupLeft{
        width: 100%;
    }

    .AuthPopupLeftTitle{
        margin-top: 20px;
        text-align: center;
        font-size: 18px;
        padding: 8px 0px;
        border-radius: 20px 20px 0px 0px;
    }

    .AuthPopupLeftSubTitle{
        text-align: center;
        font-size: 13px;
        padding: 8px 12px;
        line-height: unset;
        margin-bottom: 15px;
        font-family: 'Poppins-Medium';
    }

    .AuthPopupRight{
        width: 100%;
        flex: 1;
        border-radius: 0px 0px 25px 25px;
    }

    .AuthPopupRightSuperContainer{
        margin: 0px auto !important;
    }

    .AuthPopupRightLogoContainer{
        width: 80px;
        margin-top: 10px;
    }

    .AuthPopupRightLogo{
        width: 100%;
    }

    .AuthFormPopupButtons{
        margin: 3px;
        font-size: 14px !important;
    }

    .AuthPopupFormFieldMobile{
        width: 82vw !important;
    }

    .CenterImage{
        width: 30vw;
    }

    .RightImage, .LeftImage{
        width: 24vw;
        margin: auto;
    }

    .HomeAuthFormSubmitButtonContainer{
        margin: 0px 30px;
    }

    .DishRateModalTextArea::placeholder{
        font-size: 12px;
    }

    .DishRateModalTextArea{
        font-size: 12px;
        padding-left: 5px;
    }

    .RatingInformationContainer{
        border-top-left-radius: 15px;
    }

    .AuthFormError{
        margin-left: 0px !important;
    }

    .circle{
        margin-top: -10px;
    }

    .ddPopOverCartBtn1M {
        background: #EA5B2F;
        box-shadow: 0px 0px 0px 2px #ffffff, 0px 0px 0px 3px #EA5B2F;
    }

    @-webkit-keyframes undulate {
        0% {
            width: 50px;
            height: 50px;
            opacity: 1;
       }
       14% {
            background-color: rgba(211,211, 211, 0.78);
        }
        21% {
            background-color: rgba(211,211, 211, 0.8);
        }
        28% {
            background-color: rgba(211,211, 211, 0.82);
        }
        35% {
            background-color: rgba(211,211, 211, 0.84);
        }
        42% {
            background-color: rgba(211,211, 211, 0.86);
        }
        49% {
            background-color: rgba(211,211, 211, 0.88);
        }
        56% {
            background-color: rgba(211,211, 211, 0.9);
        }
        63% {
            background-color: rgba(211,211, 211, 0.92);
        }
        70% {
            background-color: rgba(211,211, 211, 0.94);
        }
        77% {
            background-color: rgba(211,211, 211, 0.96);
        }
        84% {
            background-color: rgba(211,211, 211, 0.98);
        }
        91% {
            background-color: rgba(211,211, 211, 1);
        }
        100% {
            background-color: #150525;
            width: 200px;
            height: 200px;
            opacity: 0;
       }
    }
    @-moz-keyframes undulate {
        0% {
            width: 50px;
            height: 50px;
            opacity: 1;
       }
       14% {
            background-color: rgba(211,211, 211, 0.78);
        }
        21% {
            background-color: rgba(211,211, 211, 0.8);
        }
        28% {
            background-color: rgba(211,211, 211, 0.82);
        }
        35% {
            background-color: rgba(211,211, 211, 0.84);
        }
        42% {
            background-color: rgba(211,211, 211, 0.86);
        }
        49% {
            background-color: rgba(211,211, 211, 0.88);
        }
        56% {
            background-color: rgba(211,211, 211, 0.9);
        }
        63% {
            background-color: rgba(211,211, 211, 0.92);
        }
        70% {
            background-color: rgba(211,211, 211, 0.94);
        }
        77% {
            background-color: rgba(211,211, 211, 0.96);
        }
        84% {
            background-color: rgba(211,211, 211, 0.98);
        }
        91% {
            background-color: rgba(211,211, 211, 1);
        }
        100% {
            background-color: #150525;
            width: 200px;
            height: 200px;
            opacity: 0;
       }
    }
    @-ms-keyframes undulate {
        0% {
            width: 50px;
            height: 50px;
            opacity: 1;
       }
       14% {
            background-color: rgba(211,211, 211, 0.78);
        }
        21% {
            background-color: rgba(211,211, 211, 0.8);
        }
        28% {
            background-color: rgba(211,211, 211, 0.82);
        }
        35% {
            background-color: rgba(211,211, 211, 0.84);
        }
        42% {
            background-color: rgba(211,211, 211, 0.86);
        }
        49% {
            background-color: rgba(211,211, 211, 0.88);
        }
        56% {
            background-color: rgba(211,211, 211, 0.9);
        }
        63% {
            background-color: rgba(211,211, 211, 0.92);
        }
        70% {
            background-color: rgba(211,211, 211, 0.94);
        }
        77% {
            background-color: rgba(211,211, 211, 0.96);
        }
        84% {
            background-color: rgba(211,211, 211, 0.98);
        }
        91% {
            background-color: rgba(211,211, 211, 1);
        }
        100% {
            background-color: #150525;
            width: 200px;
            height: 200px;
            opacity: 0;
       }
    }
    @keyframes undulate {
        0% {
            width: 50px;
            height: 50px;
            opacity: 1;
       }
       14% {
            background-color: rgba(211,211, 211, 0.78);
        }
        21% {
            background-color: rgba(211,211, 211, 0.8);
        }
        28% {
            background-color: rgba(211,211, 211, 0.82);
        }
        35% {
            background-color: rgba(211,211, 211, 0.84);
        }
        42% {
            background-color: rgba(211,211, 211, 0.86);
        }
        49% {
            background-color: rgba(211,211, 211, 0.88);
        }
        56% {
            background-color: rgba(211,211, 211, 0.9);
        }
        63% {
            background-color: rgba(211,211, 211, 0.92);
        }
        70% {
            background-color: rgba(211,211, 211, 0.94);
        }
        77% {
            background-color: rgba(211,211, 211, 0.96);
        }
        84% {
            background-color: rgba(211,211, 211, 0.98);
        }
        91% {
            background-color: rgba(211,211, 211, 1);
        }
        100% {
            background-color: #150525;
            width: 200px;
            height: 200px;
            opacity: 0;
       }
    }

    .DishImageModalText{
        transform: translateY(-20px);
    }

    .RatingModalContainer{
        /* height: 80vh; */
        margin: 5vh auto;
    }

}