@font-face {
    font-family: 'Quicksand-Regular';
    font-style: normal;
    font-weight: 900;
    src: local('Quicksand-Regular'), 
          url(../fonts/Quicksand/Quicksand-Regular.ttf) format('truetype');
}

@font-face {
font-family: 'Quicksand-Bold';
font-style: normal;
font-weight: 900;
src: local('Quicksand-Bold'), 
        url(../fonts/Quicksand/Quicksand-Bold.ttf) format('truetype');
}

@font-face {
font-family: 'Quicksand-SemiBold';
font-style: normal;
font-weight: 900;
src: local('Quicksand-SemiBold'), 
        url(../fonts/Quicksand/Quicksand-SemiBold.ttf) format('truetype');
}

#cart-sticker{
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    color: #FFFFFF;
    font-family: 'Bree-Bold';
    background: #EA5B2F 0% 0% no-repeat padding-box;
    padding-top: 15px;
    margin-right: -9px;
    font-size: 20px;
    text-transform: capitalize;
    border: 1px solid #EA5B2F;
    transform: rotate(-90deg);
    position: fixed;
    bottom: 60%;
    z-index: 1000;
    width: 180px;
    height: 40px;
}

.cartBadge{
    position: absolute;
    font-family: 'Poppins-Bold';
    width: 15px; 
    border-radius: 50%;
    border: 1px solid #EA5B2F;
    top: 18px;
    right: 92px;
    font-size: 9px;
    text-align: center;
    background: #FFFFFF;
    color: #EA5B2F;
    line-height:14px;
    transform: rotate(90deg);
}

.cartBadge-2{
    position: absolute;
    font-family: 'Poppins-Bold';
    width: 15px; 
    border-radius: 50%;
    border: 1px solid #EA5B2F;
    top: 18px;
    right: 12px;
    font-size: 9px;
    text-align: center;
    background: #FFFFFF;
    color: #EA5B2F;
    line-height:14px;
    transform: rotate(90deg);
}

.cartName{
    position: absolute;
    right: auto;
    top: 2px;
}

.shoppingIcon{
    position: absolute;
    right: 100px;
    top: 6px;
    transform: rotate(90deg);
}

#cart-card{
    color: #000000;
    font-family: 'Poppins-Medium';
    padding: 15px;
    margin-right: 10px;
    font-size: 16px;
    width: 400px;
    text-transform: capitalize;
    position: fixed;
    /* bottom: 5px; */
    top: 0%;
    right: -10px;
    z-index: 4;
    overflow: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: 5px;  
    border-radius: 20px 0px 0px 20px !important;/* Firefox */
    z-index: 40000000000 !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#cart-card::-webkit-scrollbar {
    width: 5px;
}
#cart-card::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}
#cart-card::-webkit-scrollbar-thumb {
    background: #929292; 
    border-radius: 10px;
}
#cart-card::-webkit-scrollbar-thumb:hover {
    background: #9e9e9e; 
}

#cart-cardM{
    color: #000000;
    font-family: 'Poppins-Medium';
    padding: 15px;
    margin-right: 10px;
    font-size: 16px;
    width: 400px;
    text-transform: capitalize;
    position: fixed;
    top: 0%;
    right: -10px;
    z-index: 4;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: 5px;  
    z-index: 40000000000 !important;
    border-radius: 0px 0px 20px 20px !important;/* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
#cart-cardM::-webkit-scrollbar {
    width: 5px;
}
#cart-cardM::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}
#cart-cardM::-webkit-scrollbar-thumb {
    background: #929292; 
    border-radius: 10px;
}
#cart-cardM::-webkit-scrollbar-thumb:hover {
    background: #9e9e9e; 
}

#cartFinalPrice{
    padding-left: 5%;
    padding-top: 1%;
    margin-top: 5%;
    height: 30px;
    background-color: #FFF05A;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}

.cart-details{
    font-size: 15px;
    padding: 2%;
    border-bottom: #ECECEC 1px solid;
}

.ddOrderDetailsBox{
    font-size: 15px;
    border-top: #ECECEC 1px solid;
}

.ddCFPLogo{
    width: 150px;
}

.ddOrderItemBox{
    max-height: 250px;
    overflow-y: scroll;
}
.ddOrderItemBox::-webkit-scrollbar {
    width: 10px;
}

.cart-item-details{
    display: flex;
    flex-direction: row;
}

.coupon-details{
    font-size: 15px;
    padding-left: 2%;
    justify-content: center;
    background: #FFFFFF;
    border:1px dashed #D3D3D3;
    border-radius: 5px;
}

.cart-header{
    font-family: 'Bree-SemiBold';
    font-size: 23px;
}

.items-in-cart{
    font-size: 12px;
    color: rgb(79, 147, 236);
}

.cart-food-image{
    width: 60px;
    height: 60px;
    border-radius: 3px;
    margin-left: 10px;
}

.ddCustomisationText{
    font-size: 11px;
    /* white-space: pre-wrap; */
    font-family: 'Poppins-Regular';
    line-height: 100%;
    color: #8f8d8d;
}

.ddCustomisationText2{
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 30px;
    /* white-space: pre-wrap; */
    font-family: 'Poppins-Regular';
    line-height: 100%;
    color: #5E4B45;
}

.ddOrderListBox{
    border: #F5EEEC 1px solid;
    margin-bottom: 10px;
    text-align: left;
    box-shadow: 0px 3px 6px #00000010;
}

.ddDeliveryStatusBox{
    position: relative;
    border-radius: 0px 0px 4px 4px;
    box-shadow: 0px 3px 12px #00000022;
    opacity: 1;
}

.ddStatusSticker{
    color: #FFFFFF;
    font-family: "Poppins-SemiBold";
    font-size: 12px;
    padding: 5px 20px 5px 20px;
    border-radius: 2px;
}

.ddOrderDivScroll::-webkit-scrollbar {
    width: 0px;
}
/* .ddOrderDivScroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}
.ddOrderDivScroll::-webkit-scrollbar-thumb {
    background: #929292; 
    border-radius: 10px;
}
.ddOrderDivScroll::-webkit-scrollbar-thumb:hover {
    background: #9e9e9e; 
} */

.add-quantity{
    color: black;
    border-radius: 50%;
    background: #FCD844;
    margin: 0px 5px;
    cursor: pointer;
}

.remove-quantity{
    color: black;
    border-radius: 50%;
    background: #FCD844;
    margin: 0px 5px;
    cursor: pointer;
}

.item-Qty{
    margin: 0px 5px;
    font-size: 0.75rem;
    font-family: "Poppins-Medium";
    display: inline-block;
    transform: translateY(-2px);
}

.item-QtyTemp{
    margin: 0px 5px;
    font-size: 0.75rem;
    font-family: "Poppins-Medium";
    display: inline-block;
}

.dditemOrderQty{
    margin: 0px 5px;
    font-size: 0.75rem;
    font-family: "Poppins-Medium";
    display: inline-block;
    transform: translateY(-2px);
}

.remove-Item{
    position: relative;
    color: #ff0000;
    cursor: pointer;
    top : -10px;
    left: 0px;
}

.cart-item-names{
    font-size: 0.85rem;
    font-family: "Poppins-Medium";
    margin-left: 15px;
    flex: 1;
}

.cart-item-price{
    font-size: 0.75rem;
    font-family: "Poppins-Regular";
}

.cart-Item-Controls{
    border: #E8E8E8 1px solid;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 3px 0px 3px 0px;
    height: 30px;
}

.ddcartItemControls{
    border: #E8E8E8 1px solid;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 5px 6px 3px 6px;
    height: 30px;
}

.ddAddressBody{
    height:"75px";
}

.ddAddressBody::-webkit-scrollbar {
    width: 5px;
}

.resto-distance{
    padding-left: 2%;
    font-size: 10px;
}

.coupon-name{
    padding-left: 2%;
    font-size: 13px;
    top: 25px;
    font-family: "Poppins-Regular";
    color: #5E4B45;
}

.coupon-discount-details-left{
    position: relative;
    font-size: 10px;
    top: -5px;
    padding-left: 25px;
}

.coupon-discount-details-right{
    font-size: 13px;
    padding-top: -10px;
    padding-left: 25px;
    font-family: "Poppins-Regular";
    color: #5E4B45;
}

.timer-image{
    font-size: 18px;
    position: relative;
    top: 5px;
}

.voucher-coupon-style{
    color: blue;
    font-size: 20px;
    position: relative;
    top: 13px;
}

.resto-coupon-style{
    color: rgb(248, 0, 165);
    font-size: 20px;
    position: relative;
    top: 5px;
}

.summary-header{
    position: relative;
    /* margin-left: -7px; */
    font-size: 16px;
    font-family: 'Poppins-Bold';
}

.summary-details-left{
    font-size: 12px;
    padding-bottom: 5px;
    position: relative;
    /* margin-left: -7px; */
    font-family: "Poppins-Medium";
}

.summary-details-right{
    font-size: 12px;
    padding-bottom: 5px;
    position: relative;
    font-family: "Poppins-Medium";
}

.cart-buttons{
    background-color: #FCD844;
    font-size: 12px;
    text-align: center;
    height: 40px;
    width: 370px !important;
    z-index: 1000;
    margin: 15px;
    display: flex;
    flex-direction: row;
    border-radius: 6px;
}

.cart-buttons-disabled{
    background-color: #ECECEC;
    font-size: 12px;
    text-align: center;
    height: 40px;
    width: 100%;
    z-index: 1000;
    width: 370px !important;
    margin: 15px;
    display: flex;
    flex-direction: row;
    border-radius: 6px;
}

.cart-buttons:hover{
    font-size: 13px;
    transition: 0.1s;
    cursor: pointer;
}

.cart-buttons-text{
    /* top: -7px; */
    position: relative;
}

.cart-buttons1{
    background-color: #FCD844;
    font-size: 12px;
    text-align: center;
    height: 40px;
    width: 100% !important;
    z-index: 1000;
    margin: 15px;
    display: flex;
    flex-direction: row;
    border-radius: 6px;
}

.cart-buttons1-disabled{
    background-color: #ECECEC;
    font-size: 12px;
    text-align: center;
    height: 40px;
    width: 100%;
    z-index: 1000;
    width: 100% !important;
    margin: 15px;
    display: flex;
    flex-direction: row;
    border-radius: 6px;
}

.cart-buttons1:hover{
    font-size: 13px;
    transition: 0.1s;
    cursor: pointer;
}

.cart-buttons1-text{
    /* top: -7px; */
    position: relative;
}

.top-nav-buttons{
    font-size: 15px;
    padding-left: 10px;
    padding-top: 10px;
    color: #EA5B2F;
    cursor: pointer;
}

.top-nav-buttons:hover{
    font-size: 15px;
    padding-left: 10px;
    padding-top: 10px;
    color: #000000;
    cursor: pointer;
}

.map-dimension{
    position: relative;
    width: 94%;
    height: 300px;
    padding-right: 2%;
    align-items: center;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.2);
}

.address-cards{
    background-color: #FFFFFF;
    border: 1px dashed #E5E5E5;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.2);
    margin: 0 auto;
}

.address-cards:hover{
    box-shadow: 0 10px 10px 0 rgba(0,0,0,0.2);
}

.address-cards1{
    background-color: #FFFFFF;
    border:1px solid #FCD844;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.2);
    /* margin-bottom: 15px; */
}

.address-cards1-disabled{
    background-color: #f1f0f0;
    border:1px solid #E5E5E5;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.2);
    -webkit-filter: grayscale(80%);
    -moz-filter: grayscale(80%);
    -ms-filter: grayscale(80%);
    -o-filter: grayscale(80%);
    filter: grayscale(80%);
    filter: gray;
}

.address-cards1M-disabled{
    -webkit-filter: grayscale(80%);
    -moz-filter: grayscale(80%);
    -ms-filter: grayscale(80%);
    -o-filter: grayscale(80%);
    filter: grayscale(80%);
    filter: gray;
}

.address-cards:hover{
    box-shadow: 0 10px 10px 0 rgba(0,0,0,0.2);
}

.address-icon{
    color: #2B2624;
    font-size: 20px;
    margin-top: 2%;
    margin-left: 1%;
}

.address-icon-cart{
    color: black;
    font-size: 13px !important;
}

.address-type{
    top: -6px;
    font-size: 13px;
    color: #2B2624;
    position: relative;
    cursor: pointer;
    font-family: "Poppins-Medium";
}

.address-line{
    font-size: 11px;
    position: relative;
    color: #929292;
    top: 0px;
    padding-left: 27px;
}

.paymentFinalPrice{
    padding-left: 5%;
    height: 30px;
    background-color: #FFFBD4;
}

.order-confirm-strip{
    background-color: #1f9601;
    color: white;
    font-size: 15px;
    text-align: center;
    height: 70px;
}

.order-confirm-strip-text{
    padding-top: -10%;
}

.disabledCartStickerIcon{
    color: #ff774e;
    border-radius: 50%;
    border: 1px solid #ff774e;
    font-size: 7px;
    transform: rotate(90deg);
    margin-right: 5px;
}

.enabledCartStickerIcon{
    font-size: 7px;
    transform: rotate(90deg);
    margin-right: 5px;
}

.hideCartStickerIcon{
    font-size: 7px;
    transform: rotate(90deg);
    margin-right: 5px;
    border-radius: 50%;
    border: 1px solid #ff774e;
}

.vegTag{
    color: green;
    border: 1px solid green;
    background: #FFFFFF;
}

.nonVegTag{
    color: #973F1C;
    border: 1px solid #973F1C;
    background: #FFFFFF;
    padding: 1px;
}

.nonVegTag-BestDish{
    color: #973F1C;
    border: 1px solid #973F1C;
    background: #FFFFFF;
    padding: 3px;
}

.mapMarkerTransition{
    opacity: 0.5;
    transition: opacity 0.1s;
}

.defaultAddrTxt{
    position: relative;
    color: #ff774e;
    top: -6px;
    left: 130px;
    font-size: 9px;
    cursor: pointer;
}

.deliveryButtonsL{
    display:inline-block;
    color: #FFFFFF;
    font-size: 12px;
    text-align: center;
    height: 22px;
    z-index: 1000;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    padding-left: 10px;
    padding-right: 6px;
    cursor: pointer;
}

.deliveryButtonsL:hover{
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.2);
}

.deliveryButtonsR{
    display:inline-block;
    color: #FFFFFF;
    font-size: 12px;
    text-align: center;
    height: 22px;
    z-index: 1000;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    width: 120px;
    cursor: pointer;
}

.deliveryButtonsR:hover{
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.2);
}

.delIcon{
    position: relative;
    top: 2px;
    right: 5px;
}

.dlvBtnGroup{
    position: relative;
    left: 40px;
    top: 0px;
}

.editAddress{
    background-color: #F9F9F9;
    border: #EDEDED 1px solid;
    width: 432px;
    margin-bottom: 10px;
}

.editAddressM{
    background-color: #F9F9F9;
    border: #EDEDED 1px solid;
    width: 100%;
    margin-bottom: 10px;
}

.addressText{
    padding: 25px 12px 5px 12px;
    width: 100%;
    background: #F9F9F9;
    border: 0px none;
    font-size: 14px;
    outline: none;
}
.addressText::placeholder{
    opacity: 0.4;
}

.ddRatingTextBox{
    /* padding: 25px 12px 5px 12px; */
    width: 100%;
    background: #F8F8F8;
    border: 0px none;
    font-size: 12px;
    font-family: "Poppins-Regular";
    outline: none;
}
.ddRatingTextBox::placeholder{
    opacity: 0.4;
    font-family: "Poppins-Regular";
    color: #83706A;
}

.textBoxHeader{
    color: #929292;
    position: relative;
    top: 23px;
    font-size: 11px;
    padding-top:5px;
    padding-left: 13px;
}

.addressTypeBtnGrp{
    position: relative;
    text-align: center;
    margin-top: 40px;
}

.addressType{
    display:inline-block;
    color: #000000;
    font-size: 12px;
    text-align: center;
    z-index: 1000;
    width: 140px;
    padding: 8px 10px 10px 10px;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 5px;
}

.addressTypeM{
    display:inline-block;
    color: #000000;
    font-size: 12px;
    text-align: center;
    z-index: 1000;
    width: 100px;
    padding: 5px 10px 7px 10px;
    cursor: pointer;
    margin: 2px;
    border-radius: 50px;
}

.addressType:hover{
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.2);
}

.addressEditBtn{
    display:inline-block;
    position: relative;
    color: #2B2624;
    font-size: 10px;
    text-align: center;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 2;
    cursor: pointer;
    margin-right: 10px;
    left: 15px;
    top: 7px;
}

.addressDeleteBtn{
    display:inline-block;
    position: relative;
    color: #2B2624;
    font-size: 10px;
    text-align: center;
    z-index: 2;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    margin-right: 10px;
    left: 40px;
    top: 6px;
}

.addressEditBtnIcon{
    position: relative;
    top: -3px;
    right: 0px;
}

.defaultDel{
    display:inline-block;
    position: relative;
    background: #EA5B2F;
    color: #FFFFFF;
    font-size: 10px;
    text-align: center;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    margin-right: 20px;
    top: 6px;
    border: 1px solid #EA5B2F;
}

.defaultDel:hover{
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.2);
}

.defaultDel2{
    display:inline-block;
    position: relative;
    border: 1px solid #9e9e9e;
    color: #9e9e9e;
    font-size: 10px;
    text-align: center;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    margin-right: 20px;
    top: 6px;
}

.defaultDel2:hover{
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.2);
}

.chooseAddr{
    display:inline-block;
    position: relative;
    background: #FCD844;
    color: #000000;
    font-size: 10px;
    text-align: center;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    padding: 0px 10px 0px 10px;
    cursor: pointer;
}

.chooseAddr:hover{
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.2);
}

.orderStatusName{
    position: relative;
    top: -55px;
    left: 70px;
}

.orderStatusDetails{
    position: relative;
    top: -58px;
    left: 70px;
    font-size: 12px;
}

.linearProgressBar{
    width: 600px;
    position: relative;
    top: 200px;
    right: 126px;
    transform: rotate(-270deg);
    z-index: -1;
}

.orderStatusIcons{
    color: #EA5B2F;
    background: #FFFFFF;
}

.cardSaveButton{
    background: #EA5B2F;
    border: 1px solid #EA5B2F;
    color: #FFFFFF;
    text-align: center;
    font-size: 12px;
    border-radius: 5px;
    width: 140px;
    padding: 5px;
}
.cardSaveButton:hover{
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.2);
    cursor: pointer;
}

.couponSelectBtn{
    background: #F0F5FF;
    border: 1px dotted #3356AA;
    color: #3356AA;
    text-align: center;
    font-size: 10px;
    border-radius: 5px;
    width: 70px;
    padding: 2px;
    margin-top: 12px;
    float: right;
}
.couponSelectBtn:hover{
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.2);
    cursor: pointer;
}

.cardCancelButton{
    background: #F8F8F8;
    border: 1px solid #EA5B2F;
    color: #EA5B2F;
    text-align: center;
    font-size: 12px;
    border-radius: 5px;
    width: 140px;
    padding: 5px;
}
.cardCancelButton:hover{
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.2);
    cursor: pointer;
}

.cartScrollBlock{
    max-height: 40vh;
    overflow-x: hidden;
    background: white;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.cartScrollBlock::-webkit-scrollbar {
    width: 5px;
}
.cartScrollBlock::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}
.cartScrollBlock::-webkit-scrollbar-thumb {
    background: #929292; 
    border-radius: 10px;
}
.cartScrollBlock::-webkit-scrollbar-thumb:hover {
    background: #9e9e9e; 
}